import React from "react";
import {IStock, IStockDto} from "../../model/Stock";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import StockListItemComponent from "./StockListItemComponent";


export default function StockListComponent(props: any) {
    const stocks: IStockDto[] = props.stocks

    const content =
        <>
            <Row>
                <ListGroup className="g-2">
                    <ListGroup.Item
                        className="d-flex flex-row mb-1 align-items-center fw-bolder border-0"
                        disabled>
                        <Col xs={0} sm={0} md={1}>#</Col>
                        <Col className="col-0 col-md-1">Symbol</Col>
                        <Col sm={5}>Name</Col>
                        <Col className="col-1">Price</Col>
                        <Col className="col-2">today %</Col>
                        <Col sm={2} >Exchange</Col>
                    </ListGroup.Item>
                </ListGroup>
            </Row>

            {
                stocks.map((s: IStockDto) =>
                    <Row className="g-2" key={s.id}>
                        <ListGroup className="g-2" key={s.id}>
                            <StockListItemComponent stock={s}/>
                        </ListGroup>
                    </Row>
                )
            }
        </>


    return content;

}
import React, {useEffect, useMemo, useRef, useState} from "react";
import StockService from "../../../service/StockService";
import {ITimeSeries} from "../../../model/TimeSeries";
import {IStock, IStockStreamDto} from "../../../model/Stock";
import Card from "react-bootstrap/Card";
import {CSSTransition} from "react-transition-group";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {StockChartComponent} from "../chart/StockChartComponent";
import WatchlistService from "../../../service/WatchlistService";
import AuthService from "../../../service/auth/AuthService";
import './stock-header.css';
import './../stock-page.css';
import {useQuery} from "@tanstack/react-query";
import moment, {now} from "moment";
import {ModalTradeComponent} from "../trade/ModalTradeComponent";
import {IStockFinancials} from "../../../model/StockFinancials";
import {TiArrowDown, TiArrowUp} from "react-icons/ti";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Utils from "../../../service/utils/Utils";
import {instanceOf} from "prop-types";


const STOCKS_URL = process.env.REACT_APP_STOCK_DATA_URL;
const STOCK_DATA_URL = process.env.REACT_APP_STOCK_DATA_URL;

const StockHeaderComponent = (stock: IStock) => {

    /*const stock:IStock = props;*/
    const symbol = stock?.symbol;

    const [period, setPeriod] = useState("5d");
    const [price, setPrice] = useState<number>(0);
    const [previousPrice, setPreviousPrice] = useState<number>(0);
    const [streamObj, setStreamObj] = useState<IStockStreamDto>({} as IStockStreamDto);
    const [series, setSeries] = useState<ITimeSeries[]>([]);
    const [inProp, setInProp] = useState(false);
    const [stockFinancials, setStockFinancials] = useState<IStockFinancials>({} as IStockFinancials);
    const priceNodeRef = useRef(null);
    const [displayPrice, setDisplayPrice] = useState<IDisplayPrice>({} as IDisplayPrice);
    const [staleTime, setStaleTime] = useState<number>(0);

    const [following, setFollowing] = useState(false);
    const [marketStatus, setMarketStatus] = useState<string>()


    interface IDisplayPrice {
        price: number,
        desc?: string;
        diff: number,
        percent?: number
        cssStyle?: string
        priceAH?: number,
        descAH?: string;
        diffAH?: number,
        percentAH?: number
        cssStyleAH?: string
    }


    const fetchMarketStatus = async () => {
        const response = await StockService.getMarketStatusPromise()
        setMarketStatus(response.data)
        return response.data
    }


    const fetchStockFinancials = async (_symbol: string) => {
        const response = await StockService.getStockFinancials(_symbol)
        setStockFinancials(response.data)
        //need it here to set price for the first time before any data received from price web socket
        updatePrice({
                price: response.data.price,
                previousPrice: response.data.price,
                marketStatus: marketStatus
            },
            response.data)
        return response.data
    }


    const fetchTimeSeries = async (_symbol: string, _period: string) => {
        const response = await StockService.getTimeSeries(_symbol, _period)
        setSeries(response.data)
        updatePrice(streamObj, stockFinancials)
        return response.data
    }


    const marketStatusQuery = useQuery({
        queryKey: ['fetchMarketStatus'],
        queryFn: () => fetchMarketStatus()
    })

    const stockFinancialsQuery = useQuery({
        queryKey: ['fetchStockFinancials', symbol],
        queryFn: () => fetchStockFinancials(symbol),
        enabled: marketStatus !== undefined
    })

    const timeSeriesQuery = useQuery({
        queryKey: ['fetchTimeSeries', symbol, period],
        queryFn: () => fetchTimeSeries(symbol, period),
        enabled: stockFinancials !== undefined,
        staleTime: 0
    })


    useEffect(() => {
        displayPrice.price = stockFinancials.price
        setDisplayPrice(displayPrice)
    }, [stockFinancials])


    useEffect(() => {
            console.log("hook [] ")

            AuthService.isLoggedIn() &&
            WatchlistService.isWatching(stock.id)
                .then(result => {
                        setFollowing(result.data)
                    }
                );

            const eventSource = new EventSource(STOCKS_URL + "/stream/price/" + symbol, {});

            eventSource.onerror = (err) => {
                console.error("EventSource failed:", err);
                eventSource.close();
            };

            eventSource.addEventListener("stock-time-series", (event: MessageEvent) => {
                    console.log("event.data = " + event.data)
                    const obj: IStockStreamDto = JSON.parse(event.data);
                    setPreviousPrice(price)
                    setPrice(obj.price)
                    setStreamObj(obj)
                    setMarketStatus(obj.marketStatus)

                    if (obj.eventType !== "H") {
                        if (series.length > 0) {
                            const easternTime = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
                            setSeries([...series, {
                                //time: (new Date(obj.timestamp!)).getTime() / 1000,
                                time: (new Date(easternTime)).getTime() / 1000 - 3600 * 4,
                                value: obj.price
                            }]);
                        }
                        updatePrice(obj, stockFinancials, true)
                    }


                }
            )
        }, [stockFinancials]
    )


    const periodMap = new Map<string, string>();
    periodMap.set("1h", "past hour")
    periodMap.set("1d", "today")
    periodMap.set("5d", "past 5 days")
    periodMap.set("1m", "past month")
    periodMap.set("6m", "past 6 months")
    periodMap.set("YTD", "year to day")
    periodMap.set("1y", "past year")
    periodMap.set("5y", "past 5 years")
    periodMap.set("MAX", "all time")


    const handlePeriodSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        if (period !== button.innerHTML) {
            setPeriod(button.innerHTML);
            setStaleTime(["1h", "1d"].includes(period) ? 30000 : ['5d'].includes(period) ? 1800000 : Infinity)
        }
    };


    function roundNum(x: number, p: number) {
        return +(Math.round(x * Math.pow(10, p))) / Math.pow(10, p);
    }


    function updatePrice(_streamObj: IStockStreamDto, _stockFinancilas: IStockFinancials, animation = false) {

        setInProp(animation)

        const result = {} as IDisplayPrice;
        const now: Date = new Date();
        const lastDate = _stockFinancilas.date
        const price = _streamObj.price
        const close = _stockFinancilas.close
        let first

        switch (period) {
            case '1d': {
                first = _stockFinancilas.price1d
                break;
            }
            case '5d': {
                first = _stockFinancilas.price5d
                break;
            }
            case '1m': {
                first = _stockFinancilas.price1m
                break;
            }
            case '6m': {
                first = _stockFinancilas.price6m
                break;
            }
            case 'YTD': {
                first = _stockFinancilas.priceLastYearEnd
                break;
            }
            case '1y': {
                first = _stockFinancilas.price1y
                break;
            }
            case '5y': {
                first = _stockFinancilas.price5y
                break;
            }
            case 'MAX': {
                first = _stockFinancilas.priceOldest
                break;
            }
            default: {
                first = _stockFinancilas.price5d
                break;
            }
        }


        switch (_streamObj.marketStatus) {
            case "inactive": {
                result.price = close
                result.diff = roundNum(close - first, 4)
                result.percent = roundNum(((close - first) / first) * 100, 2)
                result.desc = "At Close: on " + moment(lastDate).format('lll');
                result.cssStyle = Utils.cssStylePrice(result.diff)
                setDisplayPrice(result)
                console.log("setDisplayPrice = " + JSON.stringify(result))
                break
            }
            case "pre": {
                result.price = close
                result.diff = roundNum(close - first, 4)
                result.percent = roundNum(((close - first) / first) * 100, 2)
                result.desc = "At Close: on " + moment(lastDate).format('lll');
                result.cssStyle = Utils.cssStylePrice(result.diff)

                result.priceAH = price
                result.diffAH = roundNum(price - close, 4)
                result.percentAH = roundNum(((price - close) / close) * 100, 2)
                result.descAH = "Pre-Market: "
                result.cssStyleAH = Utils.cssStylePrice(result.diffAH)
                setDisplayPrice(result)

                break
            }
            case "active": {
                result.price = price
                result.diff = roundNum(price - first, 4)
                result.percent = roundNum(((price - first) / first) * 100, 2)
                result.desc = "As of  " +
                    now.toLocaleTimeString("en-US", {timeZone: "America/New_York", timeZoneName: "short"})
                    + ". Market open."
                result.cssStyle = Utils.cssStylePrice(result.diff)
                setDisplayPrice(result)
                break
            }
            case "after": {
                result.price = close
                result.diff = roundNum(close - first, 4)
                result.percent = roundNum(((close - first) / first) * 100, 2)
                result.desc = "At Close on " + moment(lastDate).format('MMMM Do YYYY, h:mm:ss a');
                result.cssStyle = Utils.cssStylePrice(result.diff)

                result.priceAH = price
                result.diffAH = roundNum(price - close, 4)
                result.percentAH = roundNum(((price - close) / close) * 100, 2)
                result.descAH = moment(now).format('LTS') + ". After hours."
                result.cssStyleAH = Utils.cssStylePrice(result.diffAH)
                setDisplayPrice(result)
                break
            }
            default: {
                break;
            }
        }

    }


    function follow() {
        WatchlistService.add(stock.id).then(response => setFollowing(true))
        return 1;
    }

    function unfollow() {
        WatchlistService.remove(stock.id).then(response => setFollowing(false))
    }


    const content =
        <Card className="mb-2">
            <Card.Header className="bg-light">
                <Row>
                    <CSSTransition
                        nodeRef={priceNodeRef}
                        in={inProp}
                        timeout={{
                            appear: 0,
                            enter: 1000,
                            exit: 0,
                        }}
                        classNames={"price-" + Utils.priceDirection(price - previousPrice)}
                        /* classNames={priceCssClassName}*/
                        onEntered={() => {
                            setInProp(false)
                        }}
                    >

                        <Col>
                            <div className="d-block">
                                <h3 className="d-inline">{stock?.name} ({symbol?.toUpperCase()})</h3>
                                {AuthService.isLoggedIn() &&
                                    <>
                                        <div className="d-inline float-end float-md-none align-top ms-1 mb-1">
                                            {following ?
                                                <Button id="button-unfollow-stock"
                                                        variant="outline-success"
                                                        size="sm"
                                                        className="rounded-pill ms-3 fw-semibold"
                                                        onClick={() => unfollow()}>
                                                </Button>
                                                :
                                                <Button id="button-follow-stock" variant="outline-dark"
                                                        size="sm"
                                                        className="rounded-pill ms-3 fw-semibold"
                                                        onClick={() => follow()}>
                                                    +&nbsp;Follow
                                                </Button>
                                            }
                                        </div>
                                        <div className="d-inline float-end"><ModalTradeComponent stock={stock}/>
                                        </div>
                                    </>
                                }
                            </div>

                            {displayPrice.price &&
                                <div className="my-1">
                                    <h4 ref={priceNodeRef}
                                        className={'d-inline p-1 fw-bolder price-' + Utils.priceDirection(displayPrice.diff)}>
                                        {Utils.formatPrice(displayPrice.price, 3)} </h4>
                                    <h5 className="d-inline text-secondary">USD</h5>
                                </div>
                            }

                            {displayPrice.diff &&
                                <h5 className={'d-inline ' + displayPrice.cssStyle}>
                                    <div className="d-inline fw-bolder price-value">
                                        {Utils.formatPrice(displayPrice.diff, 3, false, true)}
                                    </div>
                                    <div className="d-inline fw-bolder price-value ">({displayPrice.percent}%)
                                    </div>
                                    <div className="d-inline fw-bolder price-value mb-1">
                                        {displayPrice.diff > 0 ? <TiArrowUp size={20}/> :
                                            <TiArrowDown size={20}/>}
                                        {periodMap.get(period)}
                                    </div>
                                </h5>
                            }

                            <div className="m-1 xx-small opacity-75">{displayPrice.desc}</div>


                            {displayPrice.diffAH &&
                                <div className="ms-1 x-small">{displayPrice.descAH}
                                    <span className={displayPrice.cssStyleAH}>
                                                {displayPrice.priceAH} {displayPrice.diffAH} ({displayPrice.percentAH}%)
                                        </span>
                                </div>
                            }
                        </Col>
                    </CSSTransition>

                </Row>

            </Card.Header>
            <Card.Body className="p-0 ">


                <Container>
                    <Nav variant="underline" className="pt-1 justify-content-end" activeKey="5d">
                        <Nav.Item>
                            <Nav.Link eventKey="1d" active={period == '1d'}
                                      onClick={handlePeriodSelect}>1d</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="5d" active={period == '5d'}
                                      onClick={handlePeriodSelect}>5d</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link role="alert" eventKey="1m" active={period == '1m'}
                                      onClick={handlePeriodSelect}>1m</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="6m" active={period == '6m'}
                                      onClick={handlePeriodSelect}>6m</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="YTD" active={period == 'YTD'}
                                      onClick={handlePeriodSelect}>YTD</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="1y" active={period == '1y'}
                                      onClick={handlePeriodSelect}>1y</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="5y" active={period == '5y'}
                                      onClick={handlePeriodSelect}>5y</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="MAX" active={period == 'MAX'}
                                      onClick={handlePeriodSelect}>MAX</Nav.Link>
                        </Nav.Item>

                    </Nav>

                    <div className="min-vh-25">
                        <StockChartComponent data={series}/>
                    </div>

                </Container>


            </Card.Body>
        </Card>


    return (
        <>
            {content}
        </>
    );

}


export default StockHeaderComponent;
import React, {useEffect, useState} from "react";
import Card from 'react-bootstrap/Card';
import {Button, Form, Image, Modal, Spinner} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import * as yup from 'yup';
import {Formik} from 'formik';
import InputGroup from "react-bootstrap/InputGroup";
import {PatternFormat} from 'react-number-format';
import {IUser, UserAccount} from "../../../../model/User";
import userService from "../../../../service/UserService";
import axiosClient from "../../../../service/axios/AxiosClient";
import UserService from "../../../../service/UserService";
import avatar from "../../../../assets/images/user/default_avatar.png";
import UploadProfileImageModal from "./file-upload/UploadProfileImageModal";


function AccountSettings() {

    /*    useEffect(() => {
            UserService.getUserPromise()
                .then((resp) => setInitialValues(resp.data))
        }, []);*/

    //const [initialValues, setInitialValues] = useState<IUser>();
    const [middleNameVisible, setMiddleNameVisible] = useState<boolean>(false);
    const user: IUser = userService.getUser().response?.data
    const [modalShow, setModalShow] = useState<boolean>(false);

    async function validateUsername(value: string) {
        try {
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + "/validate-username",
                value,
                {headers: {"Content-Type": "text/plain"}}
            )
                .then((resp) => resp.data);

            console.log(response);
            return Boolean(response);
        } catch (error) {
            console.error(error);
        }
    }

    /*    const response = validateUsername("sigachev").then(x => {
            return x
        });*/

    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("First name is required"),
        lastName: yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Last name is required"),
           //middleName: yup.string(),
        username: yup.string()
            .matches(/^([a-z])([a-z0-9_-])/, "username should start with letter and  may contain lower case letters, numbers , _ or -")
            .min(5, "Too Short! (minimum size is 5)")
            .max(50, "Too Long!")
            .test(
                'username-backend-validation',
                'Username taken',

                async (value: string | undefined) => {
                    if (value === user.username)
                        return true;

                    const response = await axios.post(
                        process.env.REACT_APP_BASE_URL + "/valid-username",
                        value
                    )
                        .then((resp) => resp.data);
                    return Boolean(response);
                }
            )
            /*.matches(/^[^@]+$/, 'Should not contain @')*/
            .required("Username is required"),
        //email: yup.string().required().email('Not a proper email')*/
        email: yup.string().required("Required").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Please enter a valid email"),
        phone: yup.string(),
    });


    return (
        <>
        <UploadProfileImageModal
            show={modalShow}
            onHide={() => setModalShow(false)}
        />

        <Card className="mb-4">
            <Card.Header className="pb-1 mb-2" style={{background: "white"}}>
                <Card.Title className="h5">Account Settings</Card.Title>
                <Card.Text className="mb-2"></Card.Text>
            </Card.Header>
            <Card.Body>
                <Row className="row g-3">
                    <Col className="col-md-4 my-4 align-items-center text-center">
                        <Image src={user?.profileImage ?? avatar} alt="Admin" className="rounded-circle" width="150"/>
                        <div className="mt-4">
                            <Button variant="outline-primary" className="mx-2" onClick={() => setModalShow(true)}>Change
                                photo</Button>
                        </div>
                    </Col>

                    <Col className="col-md-8 px-4 mb-2">
                        {user &&
                            <Formik
                                validationSchema={validationSchema}
                                onSubmit={(values, {setSubmitting}) => {
                                    console.log("button pressed");
                                    try {
                                        setSubmitting(true);
                                        UserService.update(values).then((resp) =>
                                        {
                                            setSubmitting(false)
                                        })
                                        } catch (error) {
                                        console.error(error);
                                        alert('An error occurred while sending your message. Please try again later.');
                                        setSubmitting(false);
                                    }
                                }
                                }
                                enableReinitialize={true}
                                initialValues={user}
                            >
                                {({
                                      handleSubmit,
                                      submitForm,
                                      isSubmitting,
                                      isValid,
                                      dirty,
                                  handleChange,
                                  handleBlur,
                                  values, initialValues,
                                  touched,
                                  errors
                                }) => (
                                    <Form name="accountInfoForm" as={Row} className="g-4" onSubmit={handleSubmit}
                                noValidate>

                                <Form.Group as={Col} sm={12} controlId="validationFormikFirst">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        value={values.firstName ?? ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur} // This apparently updates `touched`
                                        /*isValid={!errors.firstName}*/
                                        isInvalid={!!errors.firstName}/>
                                    {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                                    <Form.Control.Feedback
                                        type="invalid">{errors.firstName as string}</Form.Control.Feedback>
                                    {/*{errors.firstName && <div>{errors.firstName}</div>}*/}
                                    {/*{<div>init_values={initialValues as string}</div>}*/}
                                </Form.Group>

                                <Form.Group as={Col} sm={12} controlId="validationFormikLast">
                                    <Form.Label className="form-label">Last name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        value={values.lastName ?? ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur} // This apparently updates `touched`
                                        /*isValid={!errors.lastName}*/
                                        isInvalid={!!errors.lastName}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{errors.lastName as string}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm={12} md={12} controlId="formikMiddleNameCheck">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Do you have middle name ?"
                                        onClick={() => setMiddleNameVisible(!middleNameVisible)}
                                    />
                                    {middleNameVisible &&
                                        <Form.Control
                                            type="text"
                                            name="middleName"
                                            placeholder="Middle Name"
                                            value={values.middleName ?? ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur} // This apparently updates `touched`
                                            /*isValid={touched.middleName && !errors.middleName}*/
                                            isInvalid={!!errors.middleName}
                                        />
                                    }
                                </Form.Group>


                                <Form.Group as={Col} sm={12} controlId="validationFormikUsername">
                                    <Form.Label>Username</Form.Label>
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="usernamePrepend">@</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            placeholder="Username"
                                            aria-describedby="usernamePrepend"
                                            value={values.username ?? ''}
                                            onChange={handleChange}
                                            /*isValid={!errors.username}*/
                                            isInvalid={!!errors.username}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.username as string}</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                {/*                                    <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                   id="allowChecked"/>
                                            <label className="form-check-label" htmlFor="allowChecked">
                                                Allow anyone to add you to their team
                                            </label>
                                        </div>
                                    </div>*/}

                                <Form.Group as={Col} sm={12} controlId="validationFormikPhone">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        placeholder="+1 (123) 456 7890"
                                        as={PatternFormat}
                                        format={"+# (###) ### ## ##"}
                                        mask={"_"}
                                        value={values.phoneNumber}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        /*isValid={!errors.phone}*/
                                        isInvalid={!!errors.phoneNumber}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{errors.phoneNumber as string}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm={12} controlId="validationEmailFormik">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        value={values.email ?? ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur} // This apparently updates `touched`
                                        /*isValid={!errors.email}*/
                                        isInvalid={!!errors.email}/>
                                    <Form.Control.Feedback
                                        type="invalid">{errors.email as string}</Form.Control.Feedback>
                                </Form.Group>


                                <Col className="col-12 text-end">
                                    <Button type="submit" variant="primary" onClick={submitForm}
                                            disabled={!(dirty && isValid && !isSubmitting)}
                                    >
                                        {isSubmitting ?
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                                Please wait...
                                            </> : "Save changes"}
                                    </Button>
                                </Col>
                            </Form>
                            )}
                    </Formik>
                    }
                </Col>
            </Row>
        </Card.Body>
        </Card>

</>
)
    ;
}

export default AccountSettings;

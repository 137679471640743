import React, {useEffect, useState} from "react";
import {IUser} from "../../../model/User";
import Image from 'react-bootstrap/Image';
import './ProfileFeed.css';


const ProfileFeed = (props: any) => {

    /*   const [stock, setStock] = useState<IStock>();

     useEffect(() => {
          StockService.getBySymbol(props).then((response: AxiosResponse<IStock>) => {
              setStock(response.data);
              console.log("stock = ", stock)
          })
      }, [])*/
const user:IUser = props.user;


    const content =
/*        <div>User
            {user.username}
        </div>*/

        <div className="container bootdey">
            <div className="col-md-12 bootstrap snippets">
{/*                <div className="panel">
                    <div className="panel-body">
                        <FloatingLabel controlId="floatingTextarea2" label="Comments">
                            <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                            />
                        </FloatingLabel>
                        <div className="mar-top clearfix">
                            <button className="btn btn-sm btn-primary pull-right" type="submit"><i className="fa fa-pencil fa-fw"></i> Share</button>
                            <a className="btn btn-trans btn-icon fa fa-video-camera add-tooltip" href="#"></a>
                            <a className="btn btn-trans btn-icon fa fa-camera add-tooltip" href="#"></a>
                            <a className="btn btn-trans btn-icon fa fa-file add-tooltip" href="#"></a>
                        </div>
                    </div>
                </div>*/}
                <div className="panel">
                    <div className="panel-body">

                        <div className="media-block">
                            <Image className="img-circle img-sm" src="https://bootdey.com/img/Content/avatar/avatar1.png" roundedCircle={true}></Image>
                            <div className="media-body">
                                <div className="mar-btm">
                                    <a href="#" className="btn-link text-semibold media-heading box-inline">Lisa D.</a>
                                    <p className="text-muted text-sm"><i className="fa fa-mobile fa-lg"></i> - From Mobile - 11 min ago</p>
                                </div>
                                <p>consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
                                <div className="pad-ver">
                                    <div className="btn-group">
                                        <a className="btn btn-sm btn-default btn-hover-success" href="#"><i className="fa fa-thumbs-up"></i></a>
                                        <a className="btn btn-sm btn-default btn-hover-danger" href="#"><i className="fa fa-thumbs-down"></i></a>
                                    </div>
                                    <a className="btn btn-sm btn-default btn-hover-primary" href="#">Comment</a>
                                </div>

                                <hr/>


                                    <div>
                                        <div className="media-block">
                                            <a className="media-left" href="#"><Image className="img-circle img-sm" alt="Profile Picture" src="https://bootdey.com/img/Content/avatar/avatar2.png"/></a>
                                            <div className="media-body">
                                                <div className="mar-btm">
                                                    <a href="#" className="btn-link text-semibold media-heading box-inline">Bobby Marz</a>
                                                    <p className="text-muted text-sm"><i className="fa fa-mobile fa-lg"></i> - From Mobile - 7 min ago</p>
                                                </div>
                                                <p>Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
                                                <div className="pad-ver">
                                                    <div className="btn-group">
                                                        <a className="btn btn-sm btn-default btn-hover-success active" href="#"><i className="fa fa-thumbs-up"></i> You Like it</a>
                                                        <a className="btn btn-sm btn-default btn-hover-danger" href="#"><i className="fa fa-thumbs-down"></i></a>
                                                    </div>
                                                    <a className="btn btn-sm btn-default btn-hover-primary" href="#">Comment</a>
                                                </div>
                                                <hr/>
                                            </div>
                                        </div>


                                    </div>
                            </div>
                        </div>

                        <div className="media-block pad-all">
                            <a className="media-left" href="#"><Image className="img-circle img-sm" alt="Profile Picture" src="https://bootdey.com/img/Content/avatar/avatar1.png"/></a>
                            <div className="media-body">
                                <div className="mar-btm">
                                    <a href="#" className="btn-link text-semibold media-heading box-inline">John Doe</a>
                                    <p className="text-muted text-sm"><i className="fa fa-mobile fa-lg"></i> - From Mobile - 11 min ago</p>
                                </div>
                                <p>Lorem ipsum dolor sit amet.</p>
                                <Image className="img-responsive thumbnail" src="https://www.bootdey.com/image/400x300" alt="Image"/>
                                    <div className="pad-ver">
                                        <span className="tag tag-sm"><i className="fa fa-heart text-danger"></i> 250 Likes</span>
                                        <div className="btn-group">
                                            <a className="btn btn-sm btn-default btn-hover-success" href="#"><i className="fa fa-thumbs-up"></i></a>
                                            <a className="btn btn-sm btn-default btn-hover-danger" href="#"><i className="fa fa-thumbs-down"></i></a>
                                        </div>
                                        <a className="btn btn-sm btn-default btn-hover-primary" href="#">Comment</a>
                                    </div>



                    </div>
                </div>
            </div>
        </div>

            </div>
        </div>


    return (
        <>
            {content}
        </>
    );

}

export default ProfileFeed;
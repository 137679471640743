import React, {useEffect, useMemo, useState} from "react";
import {Badge, Button, CardBody, Form, FormControl, Modal} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Layout from "../layout/Layout";
import Card from "react-bootstrap/Card";
import WatchlistService from "../../service/WatchlistService";
import ListGroup from "react-bootstrap/ListGroup";
import InputGroup from "react-bootstrap/InputGroup";
import CardHeader from "react-bootstrap/CardHeader";
import {IStock} from "../../model/Stock";
import toast from "react-hot-toast";
import LayoutEmpty from "../layout/LayoutEmpty";
import {Link} from "react-router-dom";


export default function WatchlistComponent(props: any) {


    const [items, setItems] = useState<IStock[]>([]);

    useEffect(() => {
        WatchlistService.getAllForCurrentUser()
            .then(response => setItems(response.data))
        console.log("items:: " + items)
    }, [])


    const [filterName, setFilterName] = useState('');
    const [filterSymbol, setFilterSymbol] = useState('');

    const filteredStocks = useMemo(() => {
        return items.filter(s => s.name.toLowerCase().includes(filterName.toLowerCase()) &&
            s.symbol.toLowerCase().includes(filterSymbol.toLowerCase()));
    }, [items, filterName, filterSymbol]);


    const [show, setShow] = useState(false);
    const [id, setId] = useState<number>();
    const [currentStock, setCurrentStock] = useState<IStock>();
    const handleClose = () => setShow(false);
    const handleShow = (s: IStock) => {
        setShow(true)
        setCurrentStock(s)
    };


    function RenderWatchlist() {
        const listItems = filteredStocks.map((stock: IStock) =>
            <ListGroup.Item
                key={stock.id}
                className="d-flex flex-row justify-content-between mb-1"
                action
                href={'/stock/' + stock.id}
                onClick={(e) => e.stopPropagation()}

            >
                <Col className="ms-2 col-5">
                    <div className="fw-bold">{stock.name} ({stock.symbol})</div>
                    {/*Cras justo odio*/}
                </Col>
                <div>$22.22</div>
                <div><Badge bg="primary" pill>
                    14
                </Badge></div>

                <Button variant="danger" size="sm" className="z-1"
                        onClick={(event) => {
                            event.preventDefault()
                            handleShow(stock)
                        }}
                >Remove</Button>
            </ListGroup.Item>

        );

        return   <ListGroup>{listItems}</ListGroup>;
    }


    function removeFromWatchlist() {
        WatchlistService.remove(currentStock!.id)
            .then(resp => toast.success("Stock is removed from your watchlist!"))
            .finally(handleClose)

    }

    const content =
        <Tab.Container id="list-group-watchlist" defaultActiveKey="#p" transition={true}>

            <Modal show={show} centered onHide={handleClose} onClick={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove <b>{currentStock?.name} ({currentStock?.symbol})</b> from
                    the list?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => removeFromWatchlist()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row className="g-3">
                <Col className="col-12 col-md-9">

                    <Card>
                        <CardHeader>
                            <h5>Watchlist</h5>
                        </CardHeader>

                        <CardBody>

                            <Col className="d-md-none">
                                <Card><CardBody><h6>Filter stocks</h6>

                                    <Form>
                                        <Row className="align-items-center">
                                            <Col xs={8}>
                                                <Form.Label htmlFor="stockNameFilter" visuallyHidden>
                                                    Name
                                                </Form.Label>
                                                <Form.Control
                                                    className="me-1"
                                                    name="stockNameFilter"
                                                    id="stockNameFilter"
                                                    placeholder="by name"
                                                    value={filterName}
                                                    onChange={event => setFilterName(event.target.value)}
                                                />
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Label htmlFor="stockSymbolFilter" visuallyHidden>
                                                    Symbol
                                                </Form.Label>
                                                <Form.Control
                                                    className="me-1"
                                                    name="stockSymbolFilter"
                                                    id="stockSymbolFilter"
                                                    placeholder="by symbol"
                                                    value={filterSymbol}
                                                    onChange={event => setFilterSymbol(event.target.value)}
                                                />
                                            </Col>


                                        </Row>
                                    </Form>

                                </CardBody>
                                </Card>
                            </Col>

                            {items.length > 0 ? RenderWatchlist() :
                                <div className="text-center ">
                                    <h6 className="display-6 mb-3">You do not have any items here.</h6>
                                    <p>Add stocks to watch list and they will appear here.</p>
                                </div>
                            }

                        </CardBody>
                    </Card>
                </Col>

                
                <Col className="col-md-3">
                    <Card><CardBody><h6>Filter stocks</h6>

                        <Form>
                            <Row className="align-items-center">
                                <Col xs="auto">
                                    <Form.Label htmlFor="stockNameFilter" visuallyHidden>
                                        Name
                                    </Form.Label>
                                    <Form.Control
                                        className="my-3"
                                        name="stockNameFilter"
                                        id="stockNameFilter"
                                        placeholder="by stock name"
                                        value={filterName}
                                        onChange={event => setFilterName(event.target.value)}
                                    />
                                </Col>
                                <Col xs="auto">
                                    <Form.Label htmlFor="stockSymbolFilter" visuallyHidden>
                                        Symbol
                                    </Form.Label>
                                    <Form.Control
                                        className="mb-3"
                                        name="stockSymbolFilter"
                                        id="stockSymbolFilter"
                                        placeholder="by stock symbol"
                                        value={filterSymbol}
                                        onChange={event => setFilterSymbol(event.target.value)}
                                    />
                                </Col>


                            </Row>
                        </Form>

                    </CardBody>
                    </Card>
                </Col>

            </Row>
        </Tab.Container>


    return (
        <Layout>
            {content}
        </Layout>
    )

}
import { useState, useEffect } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosClient from "../service/axios/AxiosClient";


const useAxios = (axiosParams: AxiosRequestConfig) => {
    const [response, setResponse] = useState<AxiosResponse>();
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState(true);

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const fetchData = async (params: AxiosRequestConfig) => {
        try {
            await timeout(5000);
            const result = await axiosClient.request(params);
            setResponse(result);
        } catch( err: any ) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };


/*    useEffect(() => {
        fetchData(axiosParams);
    },[]);*/


    const sendData = () => {
        fetchData(axiosParams);
    }

    useEffect(() => {
        if(axiosParams.method === "GET" || axiosParams.method === "get"){
            fetchData(axiosParams);
        }
    },[]);

    return { response, error, loading };
}

export default useAxios;
import React from 'react';
import Footer from "./footer/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import './layout.css';
import AlertDismissible from "../Alert/AlertDismissible";
import Header from "./header/Header";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";


function LayoutEmpty(props:any) {
    
    const x = props.center ? 'd-flex justify-content-center align-items-center' : '';
   
    return (
        <>
            <Header/>

            <Container className={"main-container " + x}>
                 {props.children}
            </Container>
            <Footer/>
        </>
    )
}


export default LayoutEmpty;

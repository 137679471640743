import {Link, useNavigate} from "react-router-dom";
import React from "react";
import LayoutEmpty from "../layout/LayoutEmpty";
import {Button} from "react-bootstrap";

export default function NotFound() {

    function goBack() {
        const navigate = useNavigate();

        return <button onClick={() => navigate(-1)}>go back</button>
    }


    return (
        <LayoutEmpty center>

            <div className="text-center ">
                <h1 className="display-1 mb-3"><b>404</b></h1>
                <h2 className="display-5 mb-3">Oops! You seem to be lost.</h2>
                <p>Here are some helpful links:</p>
                <div className="d-flex flex-row justify-content-center">
                    <div><Button className="mx-2 px-3" href='/'>Home</Button></div>
                    <div><Button variant="danger" className="mx-2 px-3" href='/' onClick={() => history.back()}>Back</Button></div>
                </div>
            </div>


        </LayoutEmpty>
    )
}

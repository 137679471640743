
import React, {useEffect, useState} from "react";




const ColoredTextComponent = (props:any) => {

    const first:number = props.first;
    const last:number = props.last;
    const input:number = props.input;

    let result;

    if (first && last) {
        return (first >= last) ? <div className="text-danger">{input}</div> : <div className="text-danger">{input}</div>
    }

    if (input < 0) {
        result = <div className="text-danger">{input}</div>
    } else {
        result = <div className="text-success">{input}</div>
    }

    return <>{result}</>
}


export default ColoredTextComponent;
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import {CardBody} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import Card from "react-bootstrap/Card";
import {useLocation} from "react-router-dom";
import stockService from "../service/StockService";
import {IStock} from "../model/Stock";
import Layout from "../components/layout/Layout";


export const StocksPage = () => {

        const [stocks, setStocks] = useState<IStock[]>([])
        const location = useLocation();

        useEffect(() => {
                stockService.getAllActive()
                    .then(response => {
                        setStocks(response.data)
                    })
            }, []
        )


        const renderPositions =
            <>
                <Row>
                    <ListGroup className="g-2">
                        <ListGroup.Item
                            className=" d-flex flex-row mb-1 align-items-center fw-bolder border-0 text-wrap text-center"
                            /*style={{fontSize: 12, lineHeight: 1.4}}*/
                            disabled>
                            <Col className="col-1">Stock #</Col>
                            <Col className="col-2">Symbol</Col>
                            <Col className="col-10">Name</Col>
                        </ListGroup.Item>
                    </ListGroup>
                </Row>
                {
                    stocks?.map((s, index) => {
                            console.log("stock: " + JSON.stringify(s))
                            //const stock:IStockDto = StockService.getById(p.stockId).response?.data
                            return <Row className="g-1" key={index}>
                                <ListGroup className="g-1" >
                                    <ListGroup.Item
                                        className="d-flex flex-row mb-1 align-items-center text-wrap text-center"
                                        action
                                        href={'/stock/' + s.symbol}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Col className="col-1">{s.id}</Col>
                                        <Col className="col-2">{s.symbol}</Col>
                                        <Col className="col-10">{s.name}</Col>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Row>
                        }
                    )
                }
            </>

        const content =
            <>

                <Row className="g-3">
                    <Col sm={12}>

                        <Card>
                            <CardHeader>
                                <h6>My positions</h6>
                            </CardHeader>

                            <CardBody className="">

                                {renderPositions}

                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </>

        return (
            <>
                <Layout>
                    {content}
                </Layout>
            </>
        );
    }
;

export default StocksPage;
import React from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import AuthService from "../service/auth/AuthService";


export default function OAuth2RedirectHandler() {

    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get("token")
    const error = searchParams.get("error")

    const navigate = useNavigate();

    console.log('TOKEN = ' + searchParams)

    if (token) {
        AuthService.setAccessToken(token)
            window.location.replace("/profile")
     /*   navigate("/profile");*/
    } else {
        /*navigate("/sign-in?error=error");*/
        window.location.replace("/sign-in?error=")
    }


    return (<></>)

}
import React, {useState} from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {Button, Form, Modal} from "react-bootstrap";
import {IStock, IStockOrder} from "../../../model/Stock";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import AuthService from "../../../service/auth/AuthService";
import toast from "react-hot-toast";
import {Formik} from "formik";
import * as yup from "yup";
import StockTradeService from "../../../service/StockTradeService";



export const ModalTradeComponent = (props: any) => {

    const stock = props?.stock

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow((s) => !s);
    const [tradeType, setTradeType] = useState("BUY")

    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .max(20, "Too Long!")
            .required("First name is required"),
        lastName: yup.string().required()
            .max(20, "Too Long!")
            .required("Last name is required"),
        /*email: yup.string().required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i, 'Not a proper email'),*/
        email: yup.string().required("Required").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Please enter a valid email"),
        password: yup.string().required("Password is a required field")
            .min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a symbol'),
        passwordConfirmation: yup.string().required("Password Confirmation is a required field").oneOf([yup.ref("password")], "Passwords must match")
    });


    return (
        <>
            <Button variant="warning" size="sm" className="border-dark-subtle border-2 float-end px-4"
                    onClick={handleShow}>Trade</Button>

            <Modal show={show} onHide={handleClose} backdrop="static" centered animation>
                <Modal.Header closeButton>
                    <Modal.Title>{stock.symbol} stock</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
             {/*       <Row>
                        User id = {user.id}
                        Stock id = {stock.id}
                    </Row>*/}

                    <Row>
                        <Col>
                            <Formik
                                validateOnMount={true}
                                /*validationSchema={validationSchema}*/
                                onSubmit={(values, {setSubmitting, resetForm}) => {
                                    // When button submits form and form is in the process of submitting, submit button is disabled
                                    setSubmitting(true);
                                    console.log('Submitting form');
                                    values.type = tradeType
                                    values.stockId = stock.id
                                    StockTradeService.submitTrade(values as IStockOrder)
                                        .then(resp => {
                                            resetForm
                                            setShow(false)
                                            toast.success("Trade executed successfully!")
                                        })
                                        .catch(err => toast.error(err));
                                    setSubmitting(false);

                                }}
                                enableReinitialize={true}
                                initialValues={
                                    {
                                        stockId: stock.id,
                                        type: tradeType,
                                        quantity: 0
                                    } as IStockOrder
                                }

                            >
                                {({
                                      isSubmitting,
                                      handleSubmit,
                                      handleChange,
                                      handleBlur,
                                      values,
                                      initialValues,
                                      touched,
                                      errors,
                                      isValid,
                                      submitForm
                                  }) => (
                                    <Form id="tradeForm" onSubmit={handleSubmit}>

                                        <ButtonGroup className="w-100 px-5 my-2" aria-label="Trade Type">
                                            <Button variant="primary" disabled={(tradeType === 'BUY')}
                                                    onClick={() => setTradeType('BUY')}>Buy</Button>
                                            <Button variant="primary" disabled={!(tradeType === 'BUY')}
                                                    onClick={() => setTradeType('SELL')}>Sell</Button>
                                        </ButtonGroup>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Price type</Form.Label>
                                            <Form.Select name="orderStatus" aria-label="trade type" disabled>
                                                <option value="buy">Market</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="tradeForm.ControlQuantity">
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min="1"
                                                step="1"
                                                name="quantity"
                                                placeholder=""
                                                value={values.quantity || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                isValid={touched.quantity && !errors.quantity}
                                                isInvalid={touched.quantity && !!errors.quantity}/>
                                        </Form.Group>




                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" form="tradeForm">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}
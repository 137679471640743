import Layout from "../../layout/Layout";
import React from "react";
import './profile-edit.css';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AccountSettings from "./account/AccountSettings";
import NotificationsSettings from "./account/NotificationsSettings";
import UserService from "../../../service/UserService";
import {IUser} from "../../../model/User";
import SecuritySettings from "./account/SecuritySettings";

const ProfileEditComponent = () => {

    //const user:IUser = UserService.getUser().response?.data;

    const content = <Tab.Container id="list-group-tabs-example" defaultActiveKey="#account">
        <Row>
            <Col className="col-lg-10">
                <Tab.Content>
                    <Tab.Pane eventKey="#account"> <AccountSettings/> </Tab.Pane>
                    <Tab.Pane eventKey="#security"> <SecuritySettings/> </Tab.Pane>
                    <Tab.Pane eventKey="#notifications"><NotificationsSettings/></Tab.Pane>
                    <Tab.Pane eventKey="#privacy">Privacy and Safety</Tab.Pane>
                    <Tab.Pane eventKey="#communications">Communications</Tab.Pane>
                    <Tab.Pane eventKey="#messaging">Messaging</Tab.Pane>
                </Tab.Content>
            </Col>

            <Col className="col-lg-2 px-0">
                <ListGroup>
                    <ListGroup.Item action href="#account">
                        Account
                    </ListGroup.Item>
                    <ListGroup.Item action href="#security">
                        Security
                    </ListGroup.Item>
                    <ListGroup.Item action href="#notifications">
                        Notifications
                    </ListGroup.Item>
                    <ListGroup.Item action href="#privacy">
                        Privacy and Safety
                    </ListGroup.Item>
                    <ListGroup.Item action href="#communications">
                        Communications
                    </ListGroup.Item>
                    <ListGroup.Item action href="#messaging">
                        Messaging
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </Row>

    </Tab.Container>


    /*    );*/

    return (
        <>
            <Layout>
                {content}
            </Layout>
        </>
    );
};

export default ProfileEditComponent;


import {IPosition, IPositionsTimeSeries} from "../model/Positions";
import axiosClient from "./axios/AxiosClient";
import {IApexTimeSeries} from "../model/TimeSeries";


const BASE_URL = process.env.REACT_APP_BASE_URL;
const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;



class UserFinancesService {

    getAllForCurrentUser() {
        return axiosClient.get<IPosition[]>(STOCKS_URL + "/positions/all")
            .then(r => r)
        /*            .catch(error => {
                            if (error.status === 401 || error.status === 403) {
                                console.log('403')
                                return Promise.resolve([])
                            }
                        }
                    );*/
    }


    getUserBalance() {
        return axiosClient.get<number>(BASE_URL + "/user/finances/balance")

    }


}

export default new UserFinancesService();
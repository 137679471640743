import {createChart, ColorType, CrosshairMode, LineStyle, AreaStyleOptions, ISeriesApi} from 'lightweight-charts';
import React, {useEffect, useRef} from 'react';
import {ITimeSeries} from "../../../model/TimeSeries";


export const StockChartComponent = (props: any) => {

    const {
        data,
        colors: {
            backgroundColor = 'white',
            textColor = 'black',
            areaBottomColor = '#ffffff',
        } = {},
    } = props;


    function priceIncreased(ts: ITimeSeries[]) {
        if (ts !== undefined && ts.length > 0) {
            return ts[0].value <= ts[ts.length - 1].value;
        }
    }


    const chartContainerRef = React.useRef<HTMLDivElement>(null);

   /*  let newSeries: ISeriesApi<any>*/
/*
        function updateSeries() {
            return newSeries.update(newValue)
        }*/

    useEffect(
        () => {
            const handleResize = () => {
                if (chartContainerRef.current) {
                    console.log("xxx")
                    chart.applyOptions({
                        width: chartContainerRef.current.clientWidth,
                        height: chartContainerRef.current.clientHeight
                    });
                }
            };


            const chart = createChart(chartContainerRef.current ? chartContainerRef.current : '', {
                layout: {
                    background: {type: ColorType.Solid, color: backgroundColor},
                    textColor,
                    attributionLogo: false
                },
                width: chartContainerRef.current?.clientWidth,
                height: window.innerHeight * 0.3 ,
                handleScroll: {
                    horzTouchDrag: true,
                    vertTouchDrag:false,
                    mouseWheel:true,
                    pressedMouseMove:true
                },
                kineticScroll: {
                    touch:true,
                    mouse:true
                },

                timeScale: {
                    lockVisibleTimeRangeOnResize: true,
                    visible: true,
                    timeVisible: true,
                    secondsVisible: false
                },
                rightPriceScale: {
                    visible: false,
                    borderVisible: false
                },
                leftPriceScale: {
                    visible: true,
                    borderVisible: false,
                    ticksVisible: true,
                }
            });


            const newSeries = chart.addAreaSeries({
                lineColor: priceIncreased(data) ? '#34a853' : '#ea4335',
                lineWidth: 2,
                crosshairMarkerRadius: 3,
                crosshairMarkerBorderWidth: 1,
                topColor: priceIncreased(data) ? '#a0e3b2' : '#f1a8a2',
                bottomColor: areaBottomColor,
                lastPriceAnimation: 2,
                lastValueVisible: true,
            });


            // Adjust the options for the priceScale of the mainSeries
            newSeries.priceScale().applyOptions({
                autoScale: false, // disables auto scaling based on visible content
            });


            newSeries.setData(data);


            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Magnet,
                    vertLine: {
                        color: 'rgba(2,0,0,0.27)',
                        style: LineStyle.Dotted,
                        labelBackgroundColor: '#0d6fff',
                    },
                    horzLine: {
                        color: 'rgba(2,0,0,0.27)',
                        style: LineStyle.Dotted,
                        labelBackgroundColor: '#0d6fff',
                    },
                },
                grid: {
                    horzLines: {
                        visible: false
                    },
                    vertLines: {
                        visible: false
                    }
                }
            });

            chart.timeScale().applyOptions({
                fixRightEdge: true,
                fixLeftEdge: true,
                lockVisibleTimeRangeOnResize: true,
                rightOffset: 0,
                uniformDistribution:true,
                ticksVisible: true,


            });

            chart.timeScale().fitContent();

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };

        },
        [data]
    );



    return (
        <div
            ref={chartContainerRef}
        />
    );
};

/*const initialData = [
    {time: '2018-12-22', value: 32.51},
    {time: '2018-12-23', value: 31.11},
    {time: '2018-12-24', value: 27.02},
    {time: '2018-12-25', value: 27.32},
    {time: '2018-12-26', value: 25.17},
    {time: '2018-12-27', value: 28.89},
    {time: '2018-12-28', value: 25.46},
    {time: '2018-12-29', value: 23.92},
    {time: '2018-12-30', value: 22.68},
    {time: '2018-12-31', value: 22.67},
];*/

import React from 'react';
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "./sidebar/Sidebar";
import './layout.css';
import AuthService from "../../service/auth/AuthService";
import {Button} from "react-bootstrap";


function Layout(props: any) {

    const {main, right, displayRight} = props;


    return (
        <>

            <Header/>

            <Container>
                <Row className="g-2 pt-2 min-vh-row">
                    <Col className="d-none d-md-block col-2">
                        {AuthService.isLoggedIn() ? <Sidebar/> :
                            <div className="d-block">
                                <h5 className="text-center mb-2">Join out community!</h5>
                                <a href="/sign-in"><Button className="w-100 mb-2 mx-auto">Login</Button></a>
                                <a href="/sign-up"><Button className="w-100 mb-2" variant="outline-danger">Sign Up</Button></a>
                            </div>
                        }
                    </Col>

                    {displayRight ?
                        <>
                            <Col className="col-12 col-md-8">
                                {main}
                            </Col>

                            <Col className="d-none d-md-block col-2">
                                {right}
                            </Col>
                        </>
                        :
                        <Col className="col-12 col-md-10">
                            {main}
                        </Col>
                    }

                </Row>

                {/*                <Navbar sticky="bottom" expand="lg" bg="dark" variant="dark">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">Sticky bottom</a>
                    </div>
                </Navbar>*/}
            </Container>

            <Footer/>


        </>
    )
}


export default Layout;

import React, {useState} from "react";
import {Outlet} from "react-router";
import {Navigate} from "react-router-dom";
import AuthService from "../../service/auth/AuthService";

/*
const useAuth = () => {
    const user = {loggedIn: false};
    return user && user.loggedIn;
};*/

const PublicRoutes = (props: any) => {
    const [isLoading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false)

    if (!props.redirect) {
        props.redirect = "/";
    }

    /*    if (response.data === false) {
            return <Outlet/>
        } else {
            return <Navigate to={props.redirect}/>
        }*/
    /*  return !AuthService.isLoggedIn() ? <Outlet/> : <Navigate to={props.redirect}/>*/

    AuthService.isLoggedInPromise()
        .then(resp => {
                setLoggedIn(resp as boolean)
                setLoading(false);
        })

    if (!isLoading)
        return !loggedIn ? <Outlet/> : <Navigate to={props.redirect}/>


};

export default PublicRoutes;

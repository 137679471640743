import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import Spinner from 'react-bootstrap/Spinner';
import AuthService from "../../service/auth/AuthService";


const LogoutComponent = () => {

    const navigate = useNavigate();

    useEffect(() => {
        AuthService.logout();
        navigate("/", {replace: true});
    }, []);

    return (
        <Spinner animation="border"/>
    );

}

export default LogoutComponent;
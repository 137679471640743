import {Link, useNavigate} from "react-router-dom";
import React from "react";
import LayoutEmpty from "../../layout/LayoutEmpty";

export default function OfflineComponent() {

    function goBack() {
        const navigate = useNavigate();

        return <button onClick={() => navigate(-1)}>go back</button>
    }


    return (
        <LayoutEmpty center>

            <h2 className="mb-2 display-5 mt-5">Hang on! We are under maintenance!</h2>
            <p>We apologize for any inconvenience caused. We are almost done.</p>


        </LayoutEmpty>
    )
}

import axiosClient from "./axios/AxiosClient";
import {IPosition} from "../model/Positions";
import {IStockOrder} from "../model/Stock";

const STOCK_DATA_URL = process.env.REACT_APP_STOCK_DATA_URL;
const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;


class StockTradeService {

    submitTrade(order: IStockOrder) {
        return axiosClient.post<IStockOrder>(STOCKS_URL + "/order/create", order)
    }

}

export default new StockTradeService();

import * as React from 'react';
import {NavLink} from 'react-router-dom';
import './Sidebar.css';
import {FaChartBar, FaListUl, FaRegComment, FaRegCommentAlt, FaRegUser} from "react-icons/fa";
import {RxDashboard} from "react-icons/rx";
import {SlWallet} from "react-icons/sl";
import {FiUsers, FiMessageCircle} from "react-icons/fi";
import {MdOutlineSsidChart} from "react-icons/md";
import {GrBarChart} from "react-icons/gr";
import Card from "react-bootstrap/Card";



function Sidebar() {

    return (

        /*<div className="sidebar-main d-flex flex-column flex-shrink-2">*/
<Card border="0" className="bg-light sticky-top" style={{top:66}}>
            <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                    <NavLink to="/dashboard" className={({isActive}) => (isActive ? "nav-link active" : "nav-link link-dark")}>
                        <RxDashboard/>
                        Dashboard
                    </NavLink>
                </li>
                <li style={{}}>
                    <NavLink to="/profile"
                             className={({isActive}) => (isActive ? "nav-link active" : "nav-link link-dark")}>
                        <FaRegUser/>
                        My Profile
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/positions"
                             className={({isActive}) => (isActive ? "nav-link active" : "nav-link link-dark")}>
                        <MdOutlineSsidChart/>
                        Positions
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/messages"
                             className={({isActive}) => (isActive ? "nav-link active" : "nav-link link-dark")}>
                        <FaRegComment/>
                        Messages
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/stock"
                             className={({isActive}) => (isActive ? "nav-link active" : "nav-link link-dark")}>
                        <GrBarChart/>
                        Stock
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/connections"
                             className={({isActive}) => (isActive ? "nav-link active" : "nav-link link-dark")}>
                        <FiUsers/>
                        Connections
                    </NavLink>
                </li>

                <li>
                    <NavLink to="/wallet"
                             className={({isActive}) => (isActive ? "nav-link active" : "nav-link link-dark")}>
                        <SlWallet/>
                        My Wallet
                    </NavLink>
                </li>
                <li>
                    <a href="/watchlist" className="nav-link link-dark">
                        <FaListUl/>
                        Watchlist
                    </a>
                </li>
            </ul>
</Card>

      /*      <hr/>
            <div className="dropdown">
                <a href="#" className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
                   id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://github.com/mdo.png" alt="" width="32" height="32"
                         className="rounded-circle me-2"/>
                    <strong>mdo</strong>
                </a>
                <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                    <li><a className="dropdown-item" href="#">New project...</a></li>
                    <li><a className="dropdown-item" href="#">Settings</a></li>
                    <li><a className="dropdown-item" href="#">Profile</a></li>
                    <li>
                        <hr className="dropdown-divider"/>
                    </li>
                    <li><a className="dropdown-item" href="#">Sign out</a></li>
                </ul>
            </div>*/
       /* </div>*/
    );
}


export default Sidebar;

import useAxios from "../hooks/useAxios";
import {IPosition, IPositionsTimeSeries} from "../model/Positions";
import axiosClient from "./axios/AxiosClient";
import UserService from "./UserService";
import AuthService from "./auth/AuthService";
import {IApexTimeSeries} from "../model/TimeSeries";


const STOCK_DATA_URL = process.env.REACT_APP_STOCK_DATA_URL;
const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;


class PositionsService {

    getAllForCurrentUser() {
        return axiosClient.get<IPosition[]>(STOCKS_URL + "/positions/all")
            .then(r => r)
        /*            .catch(error => {
                            if (error.status === 401 || error.status === 403) {
                                console.log('403')
                                return Promise.resolve([])
                            }
                        }
                    );*/
    }


    getAccountHistory() {
        return axiosClient.get<IApexTimeSeries[]>(STOCKS_URL + "/account/history")

    }


    getPositionsValue() {
        return axiosClient.get<number>(STOCKS_URL + "/positions/value")
    }
}

export default new PositionsService();
import toast from "react-hot-toast";
import {IUser, IUserRegistrationFormDTO} from "../../model/User";
import axiosClient from "../axios/AxiosClient";
import axios from "axios";


const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'access_token';
const OAUTH2_GOOGLE = BASE_URL + '/oauth2/authorization/google'

const displayToast = (message: string) => toast.success(message, {position: "top-right"});

/*const {keycloak, initialized} = useKeycloak()*/

/*let loginCount = 0;*/

class AuthService {


    login(username: string, password: string, redirectTo: string) {
        axios
            .post(BASE_URL + "/auth/login", {
                username,
                password
            })
            .then(response => {
                // console.log("login response : " + response?.data);
                let accessToken, refreshToken;
                if (response !== undefined && response.status === 200 && response.data) {
                    accessToken = response.data?.access_token
                    refreshToken = response.data?.refresh_token
                    localStorage.setItem("access_token", JSON.stringify(accessToken))
                    localStorage.setItem("refresh_token", JSON.stringify(refreshToken))
                    if (typeof redirectTo !== 'undefined') {
                        window.location.href = redirectTo
                    } else {
                        window.location.href = '/profile'
                    }
                }
            }).catch(error => {
                switch (error.response.status) {
                    case 401:
                        console.log("Username and/or password are incorrect!")
                        toast.error("Username and/or password is incorrect!")
                        break;
                    case 403:
                        console.log("Forbidden")
                        toast.error("Access denied. Email address is not confirmed.")
                        break;
                    default:
                    // code block
                }

            }
        )
    }


    logout() {
        //const navigate = useNavigate();
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        //keycloak.logout()
        //navigate("/");
    }


    async register(user
                       :
                       IUserRegistrationFormDTO
    ) {

        await axiosClient
            .post(BASE_URL + "/auth/user/create", user)
            .then(() =>
                window.location.href = '/profile'
            )
            .catch(error => {
                    if (error.status === 409) {
                        toast.error("User with this email address is already registered in the system.")
                        return false;
                    }
                    console.log("Registration error: " + error.data.message)
                    return Promise.reject(error.data.message)
                }
            )


    }

    getCurrentUser(): IUser {
        const userStr = localStorage.getItem("user");
        let user = undefined;
        if (userStr !== null && userStr !== "undefined")
            user = JSON.parse(userStr);

        return user;
    }

    setCurrentUser(user: IUser) {
        if (user !== null && user !== undefined)
            localStorage.setItem("user", JSON.stringify(user))
    }


    isCurrentUser(id: number | undefined): boolean {
        return this.getCurrentUser()?.id == id;
    }


    isLoggedIn() {
        if (localStorage.getItem('access_token')) {
            return true;
        }
    }

    /*    isLoggedIn() {
            const [isLoading, setLoading] = useState(true);
            const [loggedIn, setLoggedIn] = useState(false)
            /!*return useAxios({method: "GET", url: BASE_URL + "/auth/authenticated"}).response?.data;*!/

            axiosClient.get<boolean>(BASE_URL + "/auth/authenticated")
                .then(resp => {
                    setLoggedIn(resp.data)
                    setLoading(false);
                })

            if (!isLoading)
                return loggedIn
        }*/


    isLoggedInPromise() {
        return axiosClient.get<boolean>(BASE_URL + "/auth/authenticated")
            .then(resp => Boolean(resp.data))
            .catch(error => {
                    if (error.response.status === 401) {
                        this.getRefreshToken()
                    }
                }
            )
    }


    /*    isAuthenticated() {

            if (initialized) {
                console.log("keycloak.authenticated :: " + keycloak.authenticated?.toString())
                return keycloak.authenticated
            }
            return false

        }*/


    /*     isAuthenticated = async() => {
            const resp = await axiosClient.get(BASE_URL + "/auth/authenticated")
            return resp.data as boolean

        }*/


    getAccessToken = () => {
        let result = null;
        const storedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        storedToken && (result = JSON.parse(storedToken));
        return result;
    };

    setAccessToken(accessToken: string) {
        localStorage.setItem("access_token", JSON.stringify(accessToken))
    }


    getRefreshToken = () => {
        let result = undefined;
        const storedToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        result = JSON.parse(storedToken ?? '{}')

        return result;
    };


    refreshToken() {
        if (this.getRefreshToken !== undefined) {
            axiosClient.post(BASE_URL + "/auth/token/refresh", this.getRefreshToken)
                .then(response => {
                    if (response !== undefined && response.status === 200 && response.data) {
                        const accessToken = response.data?.access_token
                        localStorage.setItem("access_token", JSON.stringify(accessToken))
                    }
                })
                .catch(error => this.logout())
        }
    }


    //googleAuthEndpoint = BASE_URL + '/oauth2/authorization/google?redirect_uri=http://localhost:3000/oauth2/redirect';
    googleAuthEndpoint = BASE_URL + '/oauth2/authorization/google?redirect_uri=' + FRONTEND_URL + '/sign-in';


    googleLogin() {
        axiosClient
            .get(BASE_URL + "/oauth2/authorization/google?redirect_uri=' + FRONTEND_URL + '/oauth2/redirect")
            .then(response => {
                console.log("login response : " + response?.data);
            });

    }


}

export default new AuthService();

import React from 'react';
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "./sidebar/Sidebar";
import './layout.css';
import PropTypes from "prop-types";
import {useContext} from 'react';
import AppContext from "../AppContext";
import AuthService from "../../service/auth/AuthService";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Button} from "react-bootstrap";
/*class Layout extends React.Component<{ children: any }> {*/
function Layout(props: any) {

    const {children} = props;

// Get the global variables & functions via context
   /* const myContext: any = useContext(AppContext);*/
    //myContext.setThemeMode('dark')


    return (
        <>
            <Header/>

            <Container className="main-container">
                <Row className="gutter">

                    <Col className="d-none d-md-inline-grid col-md-2-35 col-lg-1-75 col-xl-1-5 col-xxl-1-1 px-0">
                        {AuthService.isLoggedIn() ? <Sidebar/> :
                        <div>
                            <ButtonGroup>
                                <Button>Login</Button>
                                <Button variant="outline-info">Sign Up</Button>
                            </ButtonGroup>
                        </div>
                        }
                    </Col>

                    <Col className="col-12 col-md-9-65 col-lg-10-25 col-xl-10-5 col-xxl-10-9">
                        {children}
                    </Col>

                </Row>
            </Container>

                <Footer/>
        </>
    )
}


export default Layout;

import React, {FormEvent, useEffect, useMemo, useState} from "react";
import {Button, CardBody, CardGroup, Form, Image, Tabs} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import {IUser} from "../../model/User";
import Layout from "../../components/layout/Layout";
import SearchService from "../../service/SearchService";
import {useSearchParams} from "react-router-dom";
import {IStock, IStockDto} from "../../model/Stock";
import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import InputGroup from "react-bootstrap/InputGroup";
import {FaSearch} from "react-icons/fa";
import {Formik} from "formik";
import avatar from "../../assets/images/user/default_avatar.png";
import StockListItemComponent from "../../components/stock/StockListItemComponent";
import StockListComponent from "../../components/stock/StockListComponent";
import * as yup from "yup";
import {ISearchResponse} from "../../model/Search";


export default function SearchPage(props: any) {

    interface IFilter {
        name: string,
        symbol: string,
    }


    const [searchText, setSearchText] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [result, setResult] = useState<ISearchResponse>()
    const [filter, setFilter] = useState<IFilter>({name: '', symbol: ''});

    /*const[initialValue, setInitialValue] = useState({text: searchText});*/


    const validationSchema = yup.object().shape({
        text: yup.string().required()
    });

    useEffect(() => {
        console.log('start searching')
        setSearchText(searchParams.get('text') ?? '');
        console.log('searchText = ' + searchText)
        /*        if (searchText.trim().length > 0) {
                    console.log('searching')
                    SearchService.search(searchText, true)
                        .then(response => {
                                setResult(response.data)
                            }
                        )
                }*/
    }, [])


    useEffect(() => {
        console.log('start searching')
        const s = searchParams.get('text') ?? ''
        if (s.length > 0) {
            console.log('searching')
            SearchService.search(s.trim(), true)
                .then(response => {
                        setResult(response.data)
                    }
                )
        }
    }, [searchParams])


        const filteredResults = useMemo(() => {
            const filterRes = result ? result : {users:[], stocks:[]};

            filterRes.users = result?.users.filter(u => u.firstName.toLowerCase().includes(filter.name.toLowerCase()) ||
                u.lastName.toLowerCase().includes(filter.name.toLowerCase()) ||
                u.username.toLowerCase().includes(filter.name.toLowerCase())) ?? []

            filterRes.stocks = result?.stocks.filter(s => s.symbol.toLowerCase().includes(filter.symbol) ||
            s.name.toLowerCase().includes(filter.symbol)) ?? []

            return filterRes;

        }, [result, filter]);

/*    const filteredStockResults = useMemo(() => {
        return result?.stocks && result.stocks.filter
    }, [result, filter])*/


    const handleChange = (value: object) => {
        setFilter(filter => ({
            ...filter,
            ...value
        }));
    }


    function RenderResults(result: ISearchResponse) {
        const users = filteredResults.users.map((u: IUser) =>
            <Row className="g-2" key={u.id}>
                <ListGroup className="g-2" key={u.id}>
                    <ListGroup.Item
                        className="d-flex flex-row justify-content-between mb-2 align-items-center"
                        action
                        href={'/user/' + u.id}
                        onClick={(e) => e.stopPropagation()}
                    >

                        <Col sm={1}>
                            <Image src={u.thumbnail ?? avatar} roundedCircle height={36}/>
                        </Col>
                        <Col sm={3}>
                            <div className="fw-bold ">{u.firstName} {u.lastName}</div>
                        </Col>

                        <Col sm={3}>@{u.username}</Col>

                        <Col sm={5} className="d-flex justify-content-end">
                            <Button variant="outline-secondary" size="sm"
                                    onClick={(event) => {
                                        event.preventDefault()
                                        /*  handleRemoveModalShow(u)*/
                                    }}
                            >Follow</Button>
                            <Button variant="success" size="sm" className="mx-2">Message</Button>
                        </Col>

                    </ListGroup.Item>
                </ListGroup>
            </Row>
        );


        let usersResult;
        if (filteredResults.users.length > 0)
            usersResult = <>
                <h5>Users</h5>
                <ListGroup>{users}</ListGroup>
            </>

        let stocksResult;
        if (filteredResults.stocks.length > 0)
            stocksResult = <>
                <h5>Stocks</h5>
                <StockListComponent stocks={result.stocks}/>
            </>


        return <Row>
            <Col sm={12}>
                {usersResult}
            </Col>
            <Col sm={12}>{stocksResult}</Col>
        </Row>
    }


    const content =
        <>
            <Row className="g-3">
                <Col className="col-12 col-md-9">

                    <Card>
                        <CardHeader>
                            <h6>Search</h6>
                        </CardHeader>

                        <CardBody className="">
                            <Formik
                                onSubmit={(values, {setSubmitting}) => {
                                    setSubmitting(true);
                                    SearchService.search(values.text, true)
                                        .then(response => {
                                                setResult(response.data)
                                                searchParams.set('text',searchText)
                                            }
                                        )
                                    setSubmitting(false);
                                }
                                }
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                initialValues={{text: searchText}}
                            >
                                {({
                                      isSubmitting,
                                      handleSubmit,
                                      handleChange,
                                      handleBlur,
                                      values,
                                      isValid,
                                      errors,
                                      touched

                                  }) => (
                                    <Form className="mb-4" onSubmit={handleSubmit}>
                                        <h5 className="ms-2">All</h5>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                className="border-2 border-end-0 "
                                                type="text"
                                                name="text"
                                                placeholder=""
                                                size="sm"
                                                value={values.text}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <Button type="submit" className="px-3" variant="outline-secondary"
                                                    id="search-button" disabled={isSubmitting || !isValid}>
                                                <FaSearch size={18}/>
                                            </Button>
                                        </InputGroup>
                                    </Form>
                                )}
                            </Formik>

                            {filteredResults.users.length == 0 && filteredResults.stocks.length == 0  ?
                                <div className=" d-flex align-items-center justify-content-center vh-50">
                                    <div className="text-center">
                                    </div>
                                </div>
                            :
                                filteredResults.users.length > 0 || filteredResults.stocks.length > 0 ? RenderResults(filteredResults) :
                                <div className=" d-flex align-items-center justify-content-center vh-50">
                                    <div className="text-center">
                                        <h6 className="display-6 mb-3">No results.</h6>
                                        <p>Please try search with different parameters.</p>
                                    </div>
                                </div>
                            }


                        </CardBody>
                    </Card>

                </Col>

                <Col className="col-md-3">
                    <Tab.Container id="search-filter-tabs" defaultActiveKey="#all">

                        <Card className="mb-3">
                            <CardBody>
                                <ListGroup variant="flush">
                                    <ListGroup.Item action href="#all">
                                        All
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#stocks">
                                        Stocks
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#users">
                                        Users
                                    </ListGroup.Item>
                                </ListGroup>
                            </CardBody>
                        </Card>

                        <Card className="mb-3">
                            <CardBody>
                                <Tab.Content>
                                    <Tab.Pane eventKey="#all">All</Tab.Pane>
                                    <Tab.Pane eventKey="#stocks">Stocks</Tab.Pane>
                                    <Tab.Pane eventKey="#users">Users</Tab.Pane>
                                </Tab.Content>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Form>
                                    <Row className="align-items-center">
                                        <Col xs="auto">
                                            <Form.Label htmlFor="stockNameFilter">
                                                Name
                                            </Form.Label>
                                            <Form.Control
                                                className="my-3"
                                                name="stockNameFilter"
                                                id="stockNameFilter"
                                                placeholder="first, last or username"
                                                value={filter.name}
                                                onChange={event => handleChange({name: event.target.value})}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Form.Label htmlFor="stockSymbolFilter">
                                                Symbol
                                            </Form.Label>
                                            <Form.Control
                                                className="mb-3"
                                                name="stockSymbolFilter"
                                                id="stockSymbolFilter"
                                                placeholder="users that hold stock"
                                                value={filter.symbol}
                                                onChange={event => handleChange({symbol: event.target.value})}
                                            />
                                        </Col>
                                    </Row>
                                </Form>

                            </CardBody>
                        </Card>
                    </Tab.Container>
                </Col>
            </Row>


        </>

    return (
        <Layout>
            {content}
        </Layout>
    )

}
import useAxios from "../hooks/useAxios";
import axiosClient from "./axios/AxiosClient";
import {IStockComment} from "../model/Stock";
import toast from "react-hot-toast";


const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;

class StockCommentsService {

    getAllForStockId(stockId: number) {
        return axiosClient({
            method: "GET",
            url: STOCKS_URL + "/stock/comments/all/stock/" + stockId,
            headers: {
                accept: '*/*'
            }
        });
    }


    postComment(comment:IStockComment) {
        return axiosClient<IStockComment>({
            method: "POST",
            url: STOCKS_URL + "/stock/comments/add",
            data: comment
        })
            .catch(function (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        toast.error(error);
                        return Promise.reject(error);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config)
                }
            )
    }


}

export default new StockCommentsService();
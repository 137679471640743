import axios from 'axios';
import axiosClient from "./axios/AxiosClient";
import useAxios from "../hooks/useAxios";
import {IUser, IUserRegistrationFormDTO} from "../model/User";
import authHeader from "./auth/auth-header";
import toast from "react-hot-toast";
import AuthService from "./auth/AuthService";


const BASE_URL = process.env.REACT_APP_BASE_URL;


class UserConnectionsService {


    followUser(id: number) {
        return axiosClient.put(BASE_URL + '/user/follow', {id: id});
    }

    unfollowUser(id: number) {
        return axiosClient.put(BASE_URL + '/user/unfollow', id);
    }


    async isFollowing(id: number) {
        let resp = {data: false};
        if (AuthService.getCurrentUser().id === id)
            resp.data = false;
        else
            resp = await axiosClient.get(BASE_URL + '/user/following/' + id)

        return resp;
    }

    //confirmed following
    getConfirmedFollowing(id: number) {
        return axiosClient.get(BASE_URL + '/user/following/confirmed/' + id)
    }

    getFollowingConnectionRequests(id: number) {
        return axiosClient.get(BASE_URL + '/user/following/req/' + id)
    }

    getFollowersConnectionRequests(id: number) {
        return axiosClient.get(BASE_URL + '/user/followers/req/' + id)
    }

    //confirmed followers: users that follow given user
    getConfirmedFollowers(id: number) {
        return axiosClient.get(BASE_URL + '/user/followers/confirmed/' + id)
    }


    getFollowingCount(id: number) {
        return axiosClient.get(BASE_URL + '/user/following/count/' + id)
    }


    getFollowersCount(id: number) {
        return axiosClient.get(BASE_URL + '/user/followers/count/' + id)
    }

    getMutualConnections(id: number) {
        return axiosClient.get(BASE_URL + '/user/connections/mutual/' + id)
    }

    getMutualConnectionsCount(id: number) {
        return axiosClient.get(BASE_URL + '/user/connections/mutual/count/' + id)
    }


    removeFollowing(userId: number) {
        return axiosClient.delete(BASE_URL + '/user/following/remove/' +  userId)
    }

    removeFollower(userId: number) {
        return axiosClient.delete(BASE_URL + '/user/follower/remove/' +  userId)
    }



}

export default new UserConnectionsService();

import axiosClient from "./axios/AxiosClient";
import toast from "react-hot-toast";
import axios from "axios";


const BASE_URL = process.env.REACT_APP_BASE_URL;

const upload = (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append("file", file);
    return axiosClient.post(BASE_URL + "/user/uploadProfileImage", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
       .catch(error => {
            console.log("error = " + error)
            toast.error(error)
        })
};

const getFiles = (): Promise<any> => {
    return axiosClient.get("/files");
};

const FileUploadService = {
    upload,
    getFiles,
};

export default FileUploadService;
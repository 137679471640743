import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

class Utils {

    round = (x: number) => {
        const y = (Math.round(x * 10000)) / 10000
        return y
    }

    formatPrice = (price: number | undefined, decimals = 2, displayCurrency = false, displaySign = false) => {
        if (price === undefined)
            return '';

        let result;
        const y = (Math.round(price * Math.pow(10, decimals))) / Math.pow(10, decimals)
        const USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        result = displayCurrency ? USDollar.format(y) : Intl.NumberFormat().format(y)
        result = displaySign ? ('+' + result).replace("+-", "-") : result

        return result
    }


    cssStylePrice = (price: number)=> {
       return  price > 0 ? 'text-success' : price < 0 ? 'text-danger' : ''
    }

    priceDirection = (price: number | undefined)=> {
     /*   return  price > 0 ? 'increase' : price < 0 ? 'decrease' : ''*/
        if (!price) {
            return ""
        } else return  price > 0 ? 'increase' : 'decrease'
    }


    coloredPriceSpanTag = (price: number): ReactJSXElement => {
        return <span className={this.cssStylePrice(price)}>{price}</span>
    }


}

export default new Utils();
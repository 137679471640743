import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const initOptions = {
    url: "https://auth.finmates.com",
    realm: "finmates",
    clientId: "finmates-app"
};



root.render(
        <React.StrictMode>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </React.StrictMode>
);



import {Link, useNavigate} from "react-router-dom";
import React from "react";
import LayoutEmpty from "../../layout/LayoutEmpty";
import {Accordion, Image} from "react-bootstrap";
import FAQ from "./images/FAQ.jpg"
import Help1 from "./images/FAQ.jpg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default function FaqComponent(props: any) {

    function goBack() {
        const navigate = useNavigate();

        return <button onClick={() => navigate(-1)}>go back</button>
    }


    return (
        <LayoutEmpty>
            <Row>
                <Col className="col-sm-none d-lg-block col-lg-2"/>
                <Col className="col-12 col-lg-8 justify-content-center">
                    <div className="position-relative p-2 mt-2 mb-4 text-center text-muted bg-body border rounded-4">
                        <Image src={FAQ} className="img-fluid "/>
                        {/*  <h1 className="text-body-emphasis">Placeholder jumbotron</h1>
                        <p className="col-lg-6 mx-auto mb-4">
                            This faded back jumbotron is useful for placeholder content. Its also a great way to add a
                            bit of
                            context to a page or section when no content is available and to encourage visitors to take
                            a
                            specific action.
                        </p>
                        <button className="btn btn-primary px-5 mb-5" type="button">
                            Call to action
                        </button>*/}
                    </div>

                    <Accordion defaultActiveKey="0" className="mb-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is the price for using Finmates.com?</Accordion.Header>
                            <Accordion.Body>
                                We are interested in growing our community and will get paid by paid professional
                                subscribers some time in the future;
                                until that time we are 100% free to use.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How do I see other people trades? Is it legal?</Accordion.Header>
                            <Accordion.Body>
                                Users of our platform decide on their own weather they want to share their financial data.
                                These way they give permissions to the group of users they choose to view their finances.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Is my information secure</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </Col>
                <Col className="col-sm-none d-lg-block col-lg-2"/>
            </Row>

        </LayoutEmpty>
    )
}

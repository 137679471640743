import React, {useEffect, useRef, useState} from "react";
import "./styles.css";
import {Button, CloseButton, Form, Image, Modal} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {FaCloudUploadAlt} from "react-icons/fa";
import FileUploadService from "../../../../../service/FileUploadService";
import toast from "react-hot-toast";


function UploadProfileImageModal(props: any) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const iconRef = useRef<HTMLInputElement>(null!);
    const [preview, setPreview] = useState('')

    useEffect(() => {
        selectedFile ? setPreview(URL.createObjectURL(selectedFile!)) : setPreview('')
    }, [selectedFile])


    const onBtnClick = () => {
        iconRef?.current.click();
    }
    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
    };
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
        const file = event.dataTransfer.files[0];
        console.log(URL.createObjectURL(file))
        setPreview(URL.createObjectURL(file))
        console.log(file);
        validateFile(file);
    };


    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        let file
        if (event.target.files)
            file = event.target.files[0]

        if (file !== undefined) {
            console.log(URL.createObjectURL(file))
            setPreview(URL.createObjectURL(file))
            console.log(file);
            validateFile(file);
        }
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //console.log(selectedFile);
        if (selectedFile)
            FileUploadService.upload(selectedFile)
                .then(() => {
                        toast.success("Image uploaded!")
                        setSelectedFile(null)
                        const pr = {...props}
                        pr.onHide

                    }
                )

    }


    const validateFile = (file: File | null) => {
        if (file) {
            if (!file.type.startsWith('image/')) {
                setError('Please select an image file');
            } else if (file.size > 10000000) {
                setError('File size is too large (Max supported size is 10Mb)');
            } else {
                setSelectedFile(file);
                setError('');
            }
        }
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal">
                        Profile image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {/*{' '}
            <h1 style={{ textAlign: 'center' }}>React JS Image Upload and Preview </h1>{' '}*/}
                        <Container>
                            <Row className="upload_zone d-flex flex-row justify-content-center"
                                 onDragEnter={handleDragEnter}
                                 onDragLeave={handleDragLeave}
                                 onDragOver={handleDragOver}
                                 onDrop={handleDrop}
                            >

                                <Col sz={12}>
                                    <div className="d-flex justify-content-center">
                                        {preview ? <>
                                            <Image src={preview} width={244} className="ms-3"/>
                                            <CloseButton onClick={() => {
                                                setSelectedFile(null)
                                                setPreview('')
                                            }}/>
                                        </> : ''}
                                    </div>

                                    <input ref={iconRef} className="file-input" type="file" onChange={handleFileInput}
                                           name="file" hidden/>

                                    <div className="d-flex justify-content-center">
                                        <a onClick={onBtnClick} role="button"><FaCloudUploadAlt size={128}
                                                                                                color="#cccccc"/></a>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <p>Choose Your Image to Upload</p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <p>Or Drop Your Image Here</p>
                                    </div>


                                    <div className="text-danger d-flex justify-content-center">
                                        {error}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" disabled={selectedFile == null}>Submit</Button>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}


export default UploadProfileImageModal;
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import LayoutEmpty from "../../layout/LayoutEmpty";
import {Accordion, Button, Form, Image} from "react-bootstrap";
import Help1 from "./images/help1.png";
import Help2 from "./images/help2.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FaSearch} from "react-icons/fa";
import InputGroup from "react-bootstrap/InputGroup";


export default function HelpCenterComponent() {

    function goBack() {
        const navigate = useNavigate();

        return <button onClick={() => navigate(-1)}>go back</button>
    }


    return (
        <LayoutEmpty center>

            <Row className="row align-items-center pt-5 pb-5 pb-lg-3">
                <Col className="col-1 col-md-3">
                    <Image src={Help1} className="img-fluid "/>
                </Col>

                <Col className="col-md-6 text-center">

                    <h1>Hi Sam, we&lsquo;re here to help.</h1>
                    <p className="mb-4">Search here to get answers to your questions.</p>

<Form>
                    <InputGroup className="mb-3">
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <InputGroup.Text id="basic-addon1"><FaSearch/></InputGroup.Text>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="search-area"
                        />
                    </InputGroup>
</Form>

                </Col>
                <Col className="col-1 col-md-3">
                    <Image src={Help2} className="img-fluid "/>
                </Col>
            </Row>


        </LayoutEmpty>
    )
}

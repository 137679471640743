import {IUser} from "../../model/User";
import React, {useEffect, useState} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import {Button, Image, Modal} from "react-bootstrap";
import WatchlistService from "../../service/WatchlistService";
import toast from "react-hot-toast";
import AuthService from "../../service/auth/AuthService";
import Row from "react-bootstrap/Row";
import avatar from "../../assets/images/user/default_avatar.png"


const ConnectionComponent = (props: any) => {

    const [connections, setConnections] = useState<IUser[]>([]);
    const [show, setShow] = useState(false);
    const [currentUser, setCurrentUser] = useState<IUser>();
    const [user, setUser] = useState<IUser>(AuthService.getCurrentUser() ?? {});

    useEffect(() => {
            setConnections(props.connections as IUser[])
    }, [props])


    function RenderConfirmedConnections() {
        const listItems = connections.map((u: IUser) =>
            <Row className="g-2" key={u.id}>
                <ListGroup className="g-2" key={u.id}>
                    <ListGroup.Item
                        className="d-flex flex-row justify-content-between mb-2 align-items-center"
                        action
                        href={'/user/' + u.id}
                        onClick={(e) => e.stopPropagation()}
                    >

                        <Col sm={1}>
                            <Image src={u.thumbnail ?? avatar} roundedCircle height={36}/>
                        </Col>
                        <Col sm={3}>
                            <div className="fw-bold ">{u.firstName} {u.lastName}</div>
                            {/*Cras justo odio*/}
                        </Col>

                        <Col sm={3}>@{u.username}</Col>

                        <Col sm={5} className="d-flex justify-content-end">
                            <Button variant="outline-danger" size="sm"
                                    onClick={(event) => {
                                        event.preventDefault()
                                        handleShow(u)
                                    }}
                            >Remove</Button>
                            <Button variant="success" size="sm" className="mx-2">Message</Button>
                        </Col>

                    </ListGroup.Item>
                </ListGroup>
            </Row>
        );

        return <ListGroup>{listItems}</ListGroup>;
    }


    const handleClose = () => setShow(false);
    const handleShow = (u: IUser) => {
        setShow(true)
        setCurrentUser(u)
    };


    function removeConnection() {
        WatchlistService.remove(currentUser!.id)
            .then(() => toast.success("Connection has been removed!"))
            .finally(handleClose)

    }


    return (
        <>
            <Modal show={show} centered onHide={handleClose} onClick={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you shure you want to
                    remove <b>{currentUser?.firstName} {currentUser?.lastName}</b> from
                    the list?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => removeConnection()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>


            {connections?.length > 0 ? RenderConfirmedConnections() :
                <div className="text-center my-5 py-5">
                    <div className="display-6 mb-3">You do not have any connections yet.</div>
                    <p>Start following others so they appear here.</p>
                </div>
            }
        </>
    )
}

export default ConnectionComponent;
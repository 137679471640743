
import React, {useEffect, useState} from "react";
import parse from "html-react-parser";
import StockService from "../../../service/StockService";
import {AxiosResponse} from "axios";
import {IStock} from "../../../model/Stock";


const StockProfile = (stock: IStock) => {

    /*   const [stock, setStock] = useState<IStock>();

     useEffect(() => {
          StockService.getBySymbol(props).then((response: AxiosResponse<IStock>) => {
              setStock(response.data);
              console.log("stock = ", stock)
          })
      }, [])*/



    const content =
        <div className="m-4">
            {stock?.description ? parse(stock?.description) : null}
        </div>


    return (
        <>
            {content}
        </>
    );

}

export default StockProfile;
import Layout from "../layout/Layout";
import React, {useEffect, useState} from "react";
import './profile.css';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Card from "react-bootstrap/Card";
import avatar from "../../assets/images/user/default_avatar.png"
import {TbWorldWww} from "react-icons/tb";
import {FiGithub} from "react-icons/fi";
import {PiTwitterLogo} from "react-icons/pi";
import {FaInstagram} from "react-icons/fa6";
import {Badge, CardBody, Image, ListGroupItem, Placeholder} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import UserService from "../../service/UserService";
import {IUser} from "../../model/User";
import ProfileFeed from "./feed/ProfileFeed";
import {useParams} from "react-router-dom";
import AuthService from "../../service/auth/AuthService";
import toast from "react-hot-toast";
import UserConnectionsService from "../../service/UserConnectionsService";
import AccountValueChartComponent from "./charts/AccountValueChartComponent";
import {IApexChartData} from "../../model/TimeSeries";
import PositionsService from "../../service/PositionsService";
import ProfileHeaderComponent from "./header/ProfileHeaderComponent";


const ProfileComponent = () => {

    const routeParams = useParams();
    const id = Number(routeParams.id ?? 0);

    const [isFollowing, setIsFollowing] = useState(false)
    const [followingCount, setFollowingCount] = useState<number>()
    const [followersCount, setFollowersCount] = useState<number>()

    const userPromise = UserService.getUser(id)
    const userResponse = userPromise.response
    const userLoading = userPromise.loading
    const user: IUser = userResponse?.data;

    //const [series, setSeries] = useState<IApexChartData[]>([{name:'', data:[]}])


    useEffect(() => {
        if (user !== undefined && user?.id != undefined) {
            console.log("UU id =" + user?.id)
            UserConnectionsService.isFollowing(user?.id).then(resp => setIsFollowing(resp.data))
            UserConnectionsService.getFollowingCount(user?.id).then(resp => setFollowingCount(resp.data))
            UserConnectionsService.getFollowersCount(user?.id).then(resp => setFollowersCount(resp.data))
        }
    }, [user]);


/*    useEffect(() => {

        PositionsService.getAccountHistory().then(resp => {
            setSeries([{
                name: 'profile value',
                data: resp.data
            }])
        })

    }, []);*/


    function follow() {
        UserConnectionsService.followUser(user?.id)
            .then(() => toast.success("Request to follow " + user.firstName + " " + user.lastName + " has been sent."))
    }


    function unfollow() {
        UserConnectionsService.unfollowUser(user?.id)
            .then(() => toast.success("You have stopped following" + user.firstName + " " + user.lastName))
    }


    const content =

        <Tab.Container id="list-group-profile" defaultActiveKey="#profile" transition={true}>
            <Row className="row  g-3">
                <Col className="col-md-3 mb-3">
                    id = {id}
                    {/*               initialized :: {String(initialized) } &nbsp;
                    authenticated :: {String(keycloak.authenticated)} &nbsp;
                    keycloak :: {String(keycloak.tokenParsed)}*/}
                    <Card>
                        <Card.Body>
                            <div className="d-flex flex-column align-items-center text-center">
                                {!userLoading &&
                                    <Image src={user?.profileImage ?? avatar} alt="Admin" rounded width="100%"/>
                                }
                                <div className="mt-3">
                                    <h4>{user?.firstName} {user?.lastName}</h4>
                                    <p className="text-secondary mb-1">@{user?.username}</p>

                                    <div className="d-flex justify-content-around fs-6 mb-2">
                                        <div className="float-start">{followersCount ?? ''} Followers</div>
                                        <div className="float-end">{followingCount ?? ''} Following</div>
                                    </div>

                                    {!AuthService.isCurrentUser(user?.id) &&

                                        <div className="d-flex">
                                            {!isFollowing ?
                                                <Button id="button-follow-user" variant="primary" className="mx-2"
                                                        onClick={() => follow()}>Follow</Button>
                                                :
                                                <Button id="button-unfollow-user" variant="outline-danger"
                                                        className="mx-2"
                                                        onClick={() => unfollow()}/>
                                            }

                                            <Button variant="outline-success" className="mx-2">Message</Button>
                                        </div>

                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="mt-3">

                        <ListGroup className="list-group list-group-flush">
                            <ListGroupItem href="#profile" action
                                           className="profile-info-tab list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="my-1">Profile Info</h6>
                                <Badge bg="secondary">Edit</Badge>
                            </ListGroupItem>
                            <ListGroupItem href="#stocks" action
                                           className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="my-1">Stocks</h6>
                                <span className="badge bg-primary rounded-pill">14</span>
                            </ListGroupItem>
                            <ListGroupItem href="#connections" action
                                           className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="my-1">Connections</h6>
                                <span className="badge bg-primary rounded-pill">14</span>
                            </ListGroupItem>
                        </ListGroup>

                    </Card>
                    <Card className="mt-3">
                        <ListGroup className="list-group list-group-flush">
                            <ListGroupItem
                                className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="mb-0">
                                    <TbWorldWww/>
                                    Website
                                </h6>
                                <span className="text-secondary">https://bootdey.com</span>
                            </ListGroupItem>
                            <ListGroupItem
                                className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="mb-0">
                                    <FiGithub></FiGithub>
                                    Github
                                </h6>
                                <span className="text-secondary">bootdey</span>
                            </ListGroupItem>
                            <ListGroupItem
                                className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="mb-0">
                                    <PiTwitterLogo/>
                                    Twitter
                                </h6>
                                <span className="text-secondary">@bootdey</span>
                            </ListGroupItem>
                            <ListGroupItem
                                className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="mb-0">

                                    Instagram
                                </h6>
                                <span className="text-secondary">bootdey</span>
                            </ListGroupItem>
                            <ListGroupItem
                                className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="mb-0">
                                    <FaInstagram/>
                                    Facebook
                                </h6>
                                <span className="text-secondary">bootdey</span>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>




                <Col className="col-md-9 mb-3">

                    <ProfileHeaderComponent user={user}/>

                    <Card>
                        <Card.Body>
                            {/*          <div className="tab-content">
                            <div className="tab-pane active" id="home" role="tabpanel">profile...</div>
                            <div className="tab-pane" id="profile" role="tabpanel">stocks...</div>
                            <div className="tab-pane" id="stocks" role="tabpanel">connections...</div>
                        </div>*/}

                            <Tab.Content>
                                <Tab.Pane eventKey="#profile">
                                    <ProfileFeed user={user}></ProfileFeed>
                                </Tab.Pane>
                                <Tab.Pane eventKey="#stocks">stocks...</Tab.Pane>
                                <Tab.Pane eventKey="#connections">connections...</Tab.Pane>
                            </Tab.Content>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Tab.Container>


    return (
        <>
            <Layout>
                {content}
            </Layout>
        </>
    )
};

export default ProfileComponent;

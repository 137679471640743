import axiosClient from "./axios/AxiosClient";


const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;

class WatchlistService {




    getAllForCurrentUser() {
        return axiosClient.get(STOCKS_URL + '/watchlist/all')
    }


    isWatching(stockId: number) {
        return axiosClient.get(STOCKS_URL + '/watchlist/check/' + stockId)
    }


    add(stockId: number) {
        return axiosClient.post(STOCKS_URL + '/watchlist/add/' + stockId)
    }


    remove(stockId: number) {
        return axiosClient.delete(STOCKS_URL + '/watchlist/remove/' +  stockId)
    }



/*    getAllForUserId(userId: number) {
        const resp = useAxios({
            method: "GET",
            url: STOCKS_URL + "/watchlist/all",
        });
        return resp;
    }*/



}

export default new WatchlistService();

import React from 'react';
import Container from 'react-bootstrap/Container';
import './Header.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Button, Image, NavDropdown} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import Col from "react-bootstrap/Col";
import Offcanvas from 'react-bootstrap/Offcanvas';
import SearchHeaderComponent from "../../Search/SearchHeaderComponent";
import Row from "react-bootstrap/Row";
import AuthService from "../../../service/auth/AuthService";
import {IUser} from "../../../model/User";


export default function Header() {

    const navigate = useNavigate();

    const user: IUser = AuthService.getCurrentUser();

    /*    const {keycloak, initialized} = useKeycloak()*/


    function logout() {
        AuthService.logout();
        navigate("/")
    }


    return (

            <Navbar collapseOnSelect sticky="top" expand="lg" bg="dark" variant="dark">
                <Container fluid="lg">
                    <Row className="flex-fill">
                        <Col className="col-2 col-md-4 d-flex align-items-center">
                            <Link to="/"><Image src="/icons/logo.png" width={33}
                                                alt="Finmates.com - Empowering financial data sharing"/></Link>&nbsp;&nbsp;
                            <Navbar.Brand className="d-none d-md-inline-flex" href="/">FinMates</Navbar.Brand>
                        </Col>


                        <Col className="col-8 col-md-4 d-flex justify-content-center" style={{position: "relative"}}>
                            <SearchHeaderComponent/>
                        </Col>


                        <Col className="col-2 col-md-4 d-flex ms-auto">

                            <Nav className="d-flex d-inline-flex ms-auto">

                                {AuthService.isLoggedIn() ?

                                    <>
                                        <Navbar.Toggle aria-controls="navbar-dark-example m-0 p-0"/>
                                        <Navbar.Collapse id="navbar-dark-menu-collapse"
                                                         className="justify-content-end m-0 p-0">
                                            <Nav className="m-0 p-0">
                                                <NavDropdown
                                                    id="nav-dropdown-account-menu"
                                                    title={user !== null && user != undefined ?
                                                        <Image src={user?.thumbnail} height={32} roundedCircle
                                                               className="pe-1"/> : 'My Account'}
                                                    menuVariant="dark"
                                                    align="end"
                                                >
                                                    <NavDropdown.Item href="/profile/edit">Settings</NavDropdown.Item>
                                                    <NavDropdown.Item href="#asystem_mode">Mode</NavDropdown.Item>
                                                    <NavDropdown.Item href="/help">Help</NavDropdown.Item>
                                                    <NavDropdown.Divider/>
                                                    <NavDropdown.Item onClick={logout}>Sign Out</NavDropdown.Item>

                                                </NavDropdown>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </>
                                    :
                                    <>
                                        <Nav.Link className="d-none d-lg-inline-block me-2" eventKey={2} href="/about">
                                            About
                                        </Nav.Link>
                                        {/*<Nav.Link className="d-none d-lg-inline-block" href="/faq">FAQs</Nav.Link>*/}
                                        <Nav.Link className="d-none d-lg-inline-block me-2" href="/help">Help</Nav.Link>

                                        <div className="d-none d-md-flex flex-row">
                                            <a href="/sign-in">
                                                <Button variant="outline-light"
                                                        className=" text-nowrap"> Login</Button>
                                            </a>
                                            <a href="/sign-up">
                                                <Button variant="primary"
                                                        className=" mx-2 text-nowrap"> Sign-up</Button>
                                            </a>
                                        </div>
                                    </>
                                }
                            </Nav>

                            <Navbar.Toggle className="d-md-none ms-auto" aria-controls="nav-offcanvas"/>
                            <Navbar.Offcanvas
                                className="d-md-none text-bg-dark"
                                id="nav-offcanvas"
                                aria-labelledby="nav-offcanvas"
                                placement="end"
                            >
                                <Offcanvas.Header closeButton closeVariant="white">
                                    <Offcanvas.Title id="nav-offcanvas">FinMates</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1 pe-3">

                                        {AuthService.isLoggedIn() ?
                                            <>
                                                <Nav.Link href="/home">Dashboard</Nav.Link>
                                                <Nav.Link href="/profile">My Profile</Nav.Link>
                                                <Nav.Link href="/messages">My Messages</Nav.Link>
                                                <Nav.Link href="/connections">My Connections</Nav.Link>
                                                <Nav.Link href="/watchlist">Watchlist</Nav.Link>
                                                <NavDropdown.Divider/>
                                                <Nav.Link onClick={logout}>Sign Out</Nav.Link>
                                            </>
                                            :
                                            <>
                                                <Nav.Link href="/faq">FAQs</Nav.Link>
                                                <Nav.Link className="me-2" eventKey={2} href="#memes">About</Nav.Link>
                                                <NavDropdown.Divider/>
                                                <div className="d-flex d-inline-block pt-2 justify-content-center">
                                                    <Nav.Link href="/sign-in">
                                                        <Button variant="outline-light"
                                                                className="mx-2 text-nowrap"> Login</Button>
                                                    </Nav.Link>
                                                    <Nav.Link href="/sign-up">
                                                        <Button variant="primary"
                                                                className="mx-2 text-nowrap"> Sign-up</Button>
                                                    </Nav.Link>
                                                </div>
                                            </>
                                        }


                                    </Nav>

                                </Offcanvas.Body>
                            </Navbar.Offcanvas>


                        </Col>
                    </Row>


                </Container>
            </Navbar>
    )
        ;
}
/*
export default Header;*/

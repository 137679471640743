import React from "react";
import Col from "react-bootstrap/Col";
import toast from 'react-hot-toast';
import * as yup from "yup";
import {Formik} from "formik";
import {IUser} from "../../../model/User";
import AuthService from "../../../service/auth/AuthService";
import SignUpComponent from "../../../components/public/sign-up/SignUpComponent";
import LayoutEmpty from "../../../components/layout/LayoutEmpty";



function SignUpPage() {

    const displayToast = () => toast.success('Here is your toast.', {position: "top-right"});

    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .max(20, "Too Long!")
            .required("First name is required"),
        lastName: yup.string().required()
            .max(20, "Too Long!")
            .required("Last name is required"),
        /*email: yup.string().required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i, 'Not a proper email'),*/
        email: yup.string().required("Required").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Please enter a valid email"),
        password: yup.string().required("Password is a required field")
            .min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a symbol'),
        passwordConfirmation: yup.string().required("Password Confirmation is a required field").oneOf([yup.ref("password")], "Passwords must match")
    });



    function handleRegistration(user: IUser) {
        AuthService.register(user)
        //alert(JSON.stringify(user, null, 2));
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    const subtitle =<>Already have an account? <a href="sign-in">Sign in here</a></>

    const content = (
        <>
            <Col className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5 pb-2">
               <SignUpComponent title="Sign Up" subtitle={subtitle}/>
            </Col>
        </>
    );


    return (
        <>
            <LayoutEmpty center>
                {content}
            </LayoutEmpty>
        </>
    );

}

export default SignUpPage;

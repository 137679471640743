import React, {useEffect} from "react";
import {IStock, IStockDto} from "../../model/Stock";
import Col from "react-bootstrap/Col";
import {Button, Image} from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import SearchService from "../../service/SearchService";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/md";


export default function StockListItemComponent(props: any) {
    const stock: IStockDto = props.stock

    const color = stock.price! >= stock.openPrice! ? 'success' : 'danger'

    function getDiff(s:IStockDto) {
        const difference = (s.price! - s.openPrice!)/s.openPrice!

        if (difference >= 0)
            return <div className="fw-semibold text-success"><MdArrowDropUp />{(Math.round(difference * 100) / 100).toFixed(2)} % </div>
        else
            return <div className="fw-semibold text-danger"><MdArrowDropDown />{(Math.round(Math.abs(difference) * 100) / 100).toFixed(2)} % </div>

    }


/*    useEffect(() => {

    }, [])*/

    const content =
        <ListGroup.Item
            className="d-flex flex-row mb-2 align-items-center"
            action
            href={'/stock/' + stock.symbol?.toUpperCase()}
            onClick={(e) => e.stopPropagation()}
        >

            <Col sm={0} md={1}><Image src={stock.logo} roundedCircle height={36}/></Col>

            <Col sm={1} className="fw-bolder">{stock.symbol.toUpperCase()}</Col>

            <Col sm={5} className="fw-bolder">{stock.name}</Col>

            <Col className={'col-1 text-nowrap fw-semibold ' + 'text-' + color}>${stock.price}</Col>

            <Col className="col-2 text-nowrap">{getDiff(stock)}</Col>

            <Col sm={2} className="">{stock.exchange?.toUpperCase()}</Col>

{/*            <Col sm={2} className="d-flex justify-content-end">
                <Button variant="success" size="sm" className="mx-2">Follow</Button>
            </Col>*/}

        </ListGroup.Item>

    return content;
}
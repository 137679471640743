import axios from 'axios'
import toast from "react-hot-toast";
import AuthService from "../auth/AuthService";


const BASE_URL = process.env.REACT_APP_BASE_URL;
const errorToast = (message: string) => toast.error(message, {position: "top-right"});

// Add a request interceptor
const axiosClient = axios.create();
axiosClient.interceptors.request.use(
    config => {
        //config.baseURL = process.env.REACT_APP_BASE_URL
        //config.headers['Content-Type'] = 'application/json';
        //config.headers['Access-Control-Allow-Origin'] = "*";
        if (AuthService.getAccessToken())
            config.headers.setAuthorization('Bearer ' + AuthService.getAccessToken())

        return config
    },
    error => {
        // Promise.reject(error)
        console.log('Axios request error : ' + error);
        //errorToast(error.message);
    }
)


axiosClient.interceptors.response.use(
    (res) => res,
    (error) => {

        if (error.response) {
            const resp = error.response;
           // console.log('Axios response error : ' + JSON.stringify(resp));

            if (resp.status === 401 || resp.status === 403) {
                //window.location.href = '/';
      /*          errorToast("Unauthorized request!");
                AuthService.refreshToken()
                return Promise.resolve(resp.data)*/
                AuthService.logout()
                //window.location.href = '/sign-in';
            } else {
                //errorToast(resp.data.message);
            }
            //throw new Error(error.response.status);
            errorToast(error.response.data.message);
            return Promise.reject(resp.data)
        }
    }
)


export default axiosClient;
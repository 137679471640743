import React, {useEffect, useState} from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from "react-bootstrap/Card";
import {CardBody} from "react-bootstrap";

import PositionsService from "../../../service/PositionsService";
import AccountValueChartComponent from "../charts/AccountValueChartComponent";
import {IApexChartData} from "../../../model/TimeSeries";
import {IUser} from "../../../model/User";



const ProfileComponent = (props:any) => {


    const user: IUser = props.user
    const [series, setSeries] = useState<IApexChartData[]>([{name:'', data:[]}])


    useEffect(() => {
        PositionsService.getAccountHistory().then(resp => {
            setSeries([{
                name: 'profile value',
                data: resp.data
            }])
        })
    }, []);




    const content =


                    <Card className="mb-3">
                        <Card.Body>

                            <Row className="row g-3 mb-3">
                                <Col className="col-sm-6 col-md-4">
                                    <Card className="overflow-hidden">
                                        <div className="bg-holder bg-card"></div>

                                        <CardBody className="position-relative">
                                            <h6>Customers<span
                                                className="badge badge-subtle-warning rounded-pill ms-2">-0.23%</span>
                                            </h6>
                                            <div className="display-4 fs-5 mb-2 fw-normal font-sans-serif text-warning">58.39k</div>
                                            <a className="fw-semi-bold fs-10 text-nowrap">See all</a>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col className="col-sm-6 col-md-4">
                                    <Card className="overflow-hidden">
                                        <div className="bg-holder bg-card"></div>

                                        <CardBody className="position-relative">
                                            <h6>Orders<span
                                                className="badge badge-subtle-info rounded-pill ms-2">0.0%</span></h6>
                                            <div className="display-4 fs-5 mb-2 fw-normal font-sans-serif text-info">23.43k</div>
                                            <a className="fw-semi-bold fs-10 text-nowrap"
                                               href="../app/e-commerce/orders/order-list.html">All orders
                                            </a>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col className="col-md-4">
                                    <Card className="card overflow-hidden">
                                        <div className="bg-holder bg-card"></div>

                                        <CardBody className="card-body position-relative">
                                            <h6>Revenue<span
                                                className="badge badge-subtle-success rounded-pill ms-2">9.54%</span>
                                            </h6>
                                            <div className="display-4 fs-5 mb-2 fw-normal font-sans-serif">$43,594
                                            </div>
                                            <a className="fw-semi-bold fs-10 text-nowrap" href="../index.html">Statistics</a>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {series[0].data?.length > 0 &&
                                        <AccountValueChartComponent series={series}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="row">
                                <Col className="col-sm-3">
                                    <h6 className="mb-0">Full Name</h6>
                                </Col>
                                <Col className="col-sm-9 text-secondary">
                                    Kenneth Valdez
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="row">
                                <Col className="col-sm-3">
                                    <h6 className="mb-0">Email</h6>
                                </Col>
                                <Col className="col-sm-9 text-secondary">
                                    fip@jukmuh.al
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="row">
                                <Col className="col-sm-3">
                                    <h6 className="mb-0">Phone</h6>
                                </Col>
                                <Col className="col-sm-9 text-secondary">
                                    (239) 816-9029
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col className="col-sm-3">
                                    <h6 className="mb-0">Mobile</h6>
                                </Col>
                                <Col className="col-sm-9 text-secondary">
                                    (320) 380-4539
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col className="col-sm-3">
                                    <h6 className="mb-0">Address</h6>
                                </Col>
                                <Col className="col-sm-9 text-secondary">
                                    Bay Area, San Francisco, CA
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="row">
                                <Col className="col-sm-12">
                                    <a className="btn btn-info " target="__blank"
                                       href="/profile/edit">Edit</a>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>



    return (
        <>
                {content}
        </>
    )
};

export default ProfileComponent;

import {Link, useNavigate} from "react-router-dom";
import React from "react";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Col from "react-bootstrap/Col";
import LayoutEmpty from "../../../components/layout/LayoutEmpty";

export default function TermsAndConditionsPage() {

    function goBack() {
        const navigate = useNavigate();

        return <button onClick={() => navigate(-1)}>go back</button>
    }


    return (
        <LayoutEmpty center>

            <Row className="g-4 py-3">
                <Col className="col-lg-8 mx-auto">

                    <Card>
                        <CardHeader className="bg-light">
                            <h1 className="card-title h4 mb-0">Terms &amp; Conditions</h1>
                        </CardHeader>
                        <Card.Body>

                            <p>&nbsp;&nbsp;&nbsp;&nbsp;Welcome to Finmates.com - Financial Social Network, a platform where users can share ideas about
                                stocks and their trades. These Terms and Conditions govern your use of our website
                                and services. By accessing or using our website and services, you agree to comply
                                with and be bound by these Terms and Conditions. If you do not agree to these Terms
                                and Conditions, please do not use our website or services.</p>

                            {/*<hr className="mt-2 mb-4"/>*/}

                            <h5 className="mt-5">1. Acceptance of Terms</h5>
                            <p>By accessing or using our website and services, you agree to be bound by these Terms
                                and Conditions, including any additional terms and conditions and policies
                                referenced herein.</p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">2. Use of Services</h5>
                            <p>You must be at least 18 years old to use our website and services. You agree to use
                                our website and services only for lawful purposes and in accordance with these Terms
                                and Conditions. You are solely responsible for your use of our website and services
                                and for any content you post or share.</p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">3. User Content</h5>
                            <p>You retain ownership of any content you post or share on our website and services. By
                                posting or sharing content, you grant us a non-exclusive, royalty-free, perpetual,
                                irrevocable, and fully sublicensable right to use, reproduce, modify, adapt,
                                publish, translate, create derivative works from, distribute, and display such
                                content.</p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">4. Prohibited Conduct</h5>
                            <p>You agree not to:
                                <ul>
                                    <li>Use our website and services for any unlawful purpose or in violation of any
                                        applicable laws or regulations.
                                    </li>
                                    <li>Post or share any content that is defamatory, obscene, offensive, or
                                        violates the rights of others.
                                    </li>
                                    <li>Provide false or misleading information or impersonate any person or
                                        entity.
                                    </li>
                                    <li>Engage in any conduct that could damage or impair our website or services or
                                        interfere with the use of our website or services by others.
                                    </li>
                                </ul>
                            </p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">5. Intellectual Property</h5>
                            <p>All content and materials available on our website and services, including text,
                                graphics, logos, images, and software, are the property of [Your Company Name] or
                                its licensors and are protected by copyright, trademark, and other intellectual
                                property laws.</p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">6. Disclaimer of Warranties</h5>
                            <p>Our website and services are provided on an &Prime;as is&Prime; and &Prime;as
                                available&Prime; basis without
                                any warranties of any kind, either express or implied. We do not warrant that our
                                website and services will be uninterrupted or error-free, that defects will be
                                corrected, or that our website and services are free of viruses or other harmful
                                components.</p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">7. Limitation of Liability</h5>
                            <p>In no event shall [Your Company Name] be liable for any indirect, incidental,
                                special, consequential, or punitive damages, including without limitation, loss of
                                profits, data, use, goodwill, or other intangible losses, arising out of or in
                                connection with your use of our website and services.</p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">8. Indemnification</h5>
                            <p>You agree to indemnify and hold [Your Company Name] harmless from and against any and
                                all claims, liabilities, damages, losses, costs, or expenses, including reasonable
                                attorneys&apos; fees, arising out of or in connection with your use of our website and
                                services or your violation of these Terms and Conditions.</p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">9. Governing Law</h5>
                            <p>These Terms and Conditions shall be governed by and construed in accordance with the
                                laws of [Your Jurisdiction], without regard to its conflict of law principles.</p>

                            <hr className="mt-2 mb-4"/>

                            <h5 className="mt-5">10. Changes to Terms and Conditions</h5>
                            <p>We reserve the right to modify or update these Terms and Conditions at any time,
                                effective upon posting the revised Terms and Conditions on our website. Your
                                continued use of our website and services after any such changes constitutes your
                                acceptance of the revised Terms and Conditions.</p>

                            <hr className="mt-2 mb-4"/>


                            <h5>Contact Us</h5>
                            <p>If you have any questions or concerns about these Terms and Conditions, please
                                contact us at <a
                                    href="mailto:admin@finmates.com">admin@finmates.com</a>.
                            </p>
                            <p>By using our website, you consent to the terms outlined in this Privacy Policy. </p>


                        </Card.Body>
                    </Card>

                </Col>
            </Row>

        </LayoutEmpty>
    )


}

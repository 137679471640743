import React from "react";
import Row from "react-bootstrap/Row";
import {Button, Carousel, Form, Image} from "react-bootstrap";
import banner1 from "./images/trade-notifications-banner-1.jpg";
import banner2 from "./images/trade-notifications-banner-2.jpg";
import banner3 from "./images/trade-copy-01.jpg";
import banner4 from "./images/banner4.jpg";
import stockMarket from "./images/stock-market-no-bg.png";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import LayoutPublic from "../../../components/layout/LayoutPublic";
import SignUpComponent from "../../../components/public/sign-up/SignUpComponent";
import '././images/banner4.jpg'


const carousel =
    <>

        <Carousel data-bs-theme="dark" className="d-none d-lg-block d-lg-block"
                  fade
                  controls={true}
                  indicators={true}
                  indicatorLabels={["Saab", "Volvo"]}
                  keyboard={true}
                  touch={true}
        >

            <Carousel.Item>
                <Image alt="First slide" src={banner1} className="w-100"/>
                <Carousel.Caption>
                    <div className="main-banner-background">
                        <h2 className="display-4 fw-normal">Instant updates</h2>
                        <h5 className="fw-medium text-secondary">Receive instant alerts regarding your friend&apos;s
                            trading activity in real-time.</h5>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>


            <Carousel.Item>
                {/*  <Image alt="First slide" src="/homepage/banner3.jpg"/>
            <Carousel.Caption>
                <h1 >Financial portfolio</h1>
                <h5>Access your financial portfolio 24/7.</h5>
            </Carousel.Caption>*/}

                <Image alt="First slide" src={banner2} className="w-100"/>
                <Carousel.Caption>
                    <div className="main-banner-background">
                        <h2 className="display-4 fw-normal">Real-time portfolios</h2>
                        <h5 className="fw-medium text-secondary">Track your mate&apos;s
                            investment accounts in real-time</h5>
                    </div>
                    {/*<div className="d-block d-lg-none">
                    <h3>Create communities</h3>
                    <p>Follow group of traders to receive track of median activity.</p>
                </div>*/}
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <Image alt="First slide" src={banner3} className="w-100"/>
                <Carousel.Caption>
                    <div className="main-banner-background">
                        <h2 className="display-4 fw-normal">Copy trading</h2>
                        <h5 className="fw-medium text-secondary"> Automatically replicate trades of successful traders
                            to benefit from their expertise</h5>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>


        </Carousel>
    </>
;

const Homepage = () => {
    return (
        <>
            <LayoutPublic fluid={carousel}>

                <Row className="">
                    <Col className="d-block d-lg-none text-center bg-body-tertiary">
                        <Image src={stockMarket} alt="Stock market"
                               className=" w-100 mb-2"></Image>
                        <h1 className="text-body-emphasis">Real-time portfolios</h1>
                        <p className="col-lg-8 mx-auto fs-5 px-4 pb-2 text-muted">
                            Track your mate&apos;s investment accounts in real-time.
                        </p>
                        <h1 className="text-body-emphasis">Share your ideas</h1>
                        <p className="col-lg-8 mx-auto px-4 fs-5 text-muted">
                            You can share your financial decisions with your mates
                            and get notifications of friends&apos; actions in exchange.
                        </p>
                    </Col>

                    {/*              <div className="b-example-divider"></div>*/}

                    <Container className="container col-xl-10 col-xxl-10 px-4 py-2">
                        <Row className="row align-items-center g-lg-5 py-5">
                            <Col className="col-md-12 col-lg-6 text-center text-lg-start">
                                <h1 className="display-4 fw-bold lh-1 text-body-emphasis mb-3">Join our community</h1>
                                <p className="col-lg-10 fs-4">Create account by filling the form or use authorization
                                    providers below.</p>
                                <Button role="link" href="/sign-up" className=" d-lg-none mx-3">SignUp</Button>
                            </Col>
                            <Col className="d-none d-lg-block col-lg-6 mx-auto">
                                <SignUpComponent/>
                            </Col>
                        </Row>
                    </Container>

                </Row>
            </LayoutPublic>
        </>
    );
};

export default Homepage;

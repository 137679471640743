import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LayoutEmpty from "../../layout/LayoutEmpty";
import AuthService from "../../../service/auth/AuthService";
import WatchlistService from "../../../service/WatchlistService";
import {IApexChartData, IApexTimeSeries} from "../../../model/TimeSeries";

export default function AccountValueChartComponent(props: any) {

    const series:IApexChartData[] = props.series
    const [color, setColor] = useState<string[]>(['#34a853', '#c2e5cb']);
    const [options, setOptions] = useState<ApexOptions>({
            chart: {
                type: 'area',
                stacked: false,
                height: 250,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'pan'
                }
            },
            yaxis: {
                labels: {
                    formatter: function (val: any) {
                        return val;
                    },
                }
            },
            xaxis: {
                type: 'datetime',
            }

        }
    );

    useEffect(() => {

        setOptions({
                chart: {
                    type: 'area',
                    stacked: false,
                    height: 350,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        autoSelected: 'pan'
                    }
                },
                stroke: {
                    show: true,
                    curve: 'straight',
                    lineCap: 'round',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                },
                title: {
                    text: '',
                    align: 'center'
                },
                colors: getColor(props.series[0].data),
                fill: {
                    type: 'gradient',
                    colors: getColor(props.series[0].data),
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.5,
                        opacityTo: 0.1,
                        stops: [10, 100]
                    },
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        formatter: function (val: any) {
                            return val;
                        },
                    },
                    /*             title: {
                                     text: 'Price'
                                 },*/
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: false,
                    x: {
                        show: false,
                        format: 'dd MMM yyyy',
                        formatter: undefined,
                    },
                    y: {
                        formatter: function (value) {
                            return '$' + value
                        }
                    },
                    marker: {
                        show: true,
                    },

                }
            }
        )
    }, [props]);

    function getColor(ts: IApexTimeSeries[]) {
        /*console.log("ts[0] = " + JSON.stringify(ts[0]))
        console.log("ts[ts.length - 1] = " + JSON.stringify(ts[ts.length - 1]))*/
        if (ts.length > 0) {
            if (ts[0].y > ts[ts.length - 1].y) {
                return ['#ea4335', '#fffafa']
            } else {
                return ['#34a853', '#c2e5cb']
            }
        }

    }


    /*    const series = [{
            name: 'XYZ MOTORS',
            data: [
                {
                    "x": 1709730000000,
                    "y": 170.125
                },
                {
                    "x": 1709733600000,
                    "y": 169.44
                },
                {
                    "x": 1709733600000,
                    "y": 169.665
                },
                {
                    "x": 1709737200000,
                    "y": 169.155
                },
                {
                    "x": 1709740800000,
                    "y": 168.945
                },
                {
                    "x": 1709805600000,
                    "y": 169.71
                },
                {
                    "x": 1709809200000,
                    "y": 168.76
                },
                {
                    "x": 1709812800000,
                    "y": 169.17
                },
                {
                    "x": 1709816400000,
                    "y": 169.11
                },
                {
                    "x": 1709820000000,
                    "y": 170.5
                },
                {
                    "x": 1709823600000,
                    "y": 170.235
                },
                {
                    "x": 1709827200000,
                    "y": 170.065
                }
            ]
        }]*/


    /*    const options: ApexOptions = */


    return (
        /*        <LayoutEmpty center>
                    <Container>
                        <Row className="mt-4">
                            <Col>*/

        <ReactApexChart options={options} series={props.series} type="area" height={250}/>
        /*                    </Col>
                        </Row>
                    </Container>
                </LayoutEmpty>*/
    );

}

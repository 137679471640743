import React from "react"
import './Footer.css';
import {now} from "moment";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {FaListUl, FaRegUser} from "react-icons/fa";
import AuthService from "../../../service/auth/AuthService";


function Footer() {
    return (
        <>

            <div className="footer">
                <ul className="nav border-bottom flex-nowrap justify-content-center d-flex flex-row">
                    <li className="nav-item"><a href="/" className="nav-link px-2 text-muted">Home</a></li>
                    <li className="nav-item"><a href="/privacy-policy" className="nav-link px-2 text-muted">Privacy
                        Policy</a></li>
                    <li className="nav-item"><a href="/terms-and-conditions" className="nav-link px-2 text-muted">Terms
                        & Conditions</a>
                    </li>
                    <li className="nav-item"><a href="/faq" className="nav-link px-2 text-muted">FAQs</a></li>
                    <li className="nav-item"><a href="/about" className="nav-link px-2 text-muted">About</a></li>
                </ul>
                <p className="text-center text-muted py-1 my-0">©{new Date(now()).getFullYear()} FinMates, Inc</p>

            </div>

            {AuthService.isLoggedIn() &&
                <Navbar className="d-md-none d-flex justify-content-around" sticky="bottom" bg="light" expand="lg">
                    <div className="text-center small xx-small">
                        <div><FaRegUser size={18}/></div>
                        <div><small className="x-small">Account</small></div>
                    </div>
                    <div className="text-center">
                        <div><FaRegUser size={18}/></div>
                        <div><small className="x-small">Stocks</small></div>
                    </div>
                    <div className="text-center">
                        <div><FaRegUser size={20}/></div>
                        <div><small className="x-small">Messages</small></div>
                    </div>
                    <div className="text-center">
                        <div><FaRegUser size={20}/></div>
                        <div><small className="x-small">Subscriptions2</small></div>
                    </div>
                </Navbar>
            }

        </>
    );
}

export default Footer;

import React, {useEffect, useState} from "react";
import Layout from "../../components/layout/Layout";
import SearchService from "../../service/SearchService";
import {IPosition} from "../../model/Positions";
import positionsService from "../../service/PositionsService";
import {IUser} from "../../model/User";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import {Button, CardBody, Image, Modal} from "react-bootstrap";

import avatar from "../../assets/images/user/default_avatar.png";
import CardHeader from "react-bootstrap/CardHeader";
import Card from "react-bootstrap/Card";
import SignInComponent from "../../components/public/sign-in/SignInComponent";
import {useLocation} from "react-router-dom";
import StockService from "../../service/StockService";
import {IStockDto} from "../../model/Stock";
import Utils from "../../service/utils/Utils";
import {Cell} from "recharts";


export const PositionsPage = () => {

        const [positions, setPositions] = useState<IPosition[]>([])
        const [showLogin, setShowLogin] = useState(false);
        const handleCloseLogin = () => setShowLogin(false);
        const handleShowLogin = () => setShowLogin(true);
        const location = useLocation();

        useEffect(() => {
                positionsService.getAllForCurrentUser()
                    .then(response => {
                        setPositions(response.data)
                    })
            }, []
        )

/*    const { Column, HeaderCell, Cell } = Table;*/

/*    const renderPositions1 =*/
/*        <Table
            height={400}
            data={positions}
            onRowClick={rowData => {
                console.log(rowData);
            }}
        >
            <Column width={150}>
                <HeaderCell>Symbol</HeaderCell>
                <Cell dataKey="symbol" />
            </Column>

            <Column width={150}>
                <HeaderCell>Price</HeaderCell>
                <Cell dataKey="currentPrice" />
            </Column>
        </Table>*/



        const renderPositions =
            <>
                <Row>
                    <ListGroup className="g-2">
                        <ListGroup.Item
                            className=" d-flex flex-row mb-1 align-items-center fw-bolder border-0 text-wrap text-center"
                            /*style={{fontSize: 12, lineHeight: 1.4}}*/
                            disabled>
                            <Col className="col-1-5">Stock #</Col>
                            <Col className="col-1-5"><div className="w-100">Price</div> Paid
                            </Col>
                            <Col className="col-1-5"><div className="w-100">Current</div> Price
                            </Col>
                            <Col className="col-1-5">Qty</Col>
                            <Col className="col-1-5"><div className="w-100">Total</div> Cost
                            </Col>
                            <Col className="col-1-5"><div className="w-100"> Market</div> Value</Col>
                            <Col sm={1}><div className="w-100">Total</div> Gain</Col>
                            <Col sm={2} className="text-center">Actions</Col>
                        </ListGroup.Item>
                    </ListGroup>
                </Row>
                {
                    positions && positions?.map((p, index) => {
                            console.log("position: " + JSON.stringify(p))
                            //const stock:IStockDto = StockService.getById(p.stockId).response?.data
                            return <Row className="g-1" key={index}>
                                <ListGroup className="g-1" >
                                    <ListGroup.Item
                                        className="d-flex flex-row mb-1 align-items-center text-wrap text-center"
                                        action
                                        href={'/stock/' + p.symbol}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Col className="col-1-5"><div className="fw-bolder">{p.symbol}</div></Col>
                                        <Col className="col-1-5">{p.averagePrice}</Col>
                                        <Col className="col-1-5">{p.currentPrice}</Col>
                                        <Col className="col-1-5">{p.quantity}</Col>
                                        <Col className="col-1-5">{Utils.formatPrice(p.averagePrice! * p.quantity)}</Col>
                                        <Col className="col-1-5">{Utils.formatPrice(p.currentPrice! * p.quantity)}</Col>
                                        <Col sm={1}>Qty</Col>
                                        <Col sm={2} className="text-end">
                                            <Button variant="success" size="sm" className="me-2"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        /*  handleRemoveModalShow(u)*/
                                                    }}
                                            >Buy</Button>
                                            <Button variant="danger" size="sm"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        /*  handleRemoveModalShow(u)*/
                                                    }}
                                            >Sell</Button>
                                        </Col>

                                    </ListGroup.Item>
                                </ListGroup>
                            </Row>
                        }
                    )
                }
            </>

        const content =
            <>
                <Modal show={showLogin} onHide={handleCloseLogin} centered size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Please sign-in to add comments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="m-1 m-md-3 px-2 px-md-4"><SignInComponent redirectTo={location.pathname}/></div>
                    </Modal.Body>
                </Modal>



                <Row className="g-3">
                    <Col sm={12}>

                        <Card>
                            <CardHeader>
                                <h6>My positions</h6>
                            </CardHeader>

                            <CardBody className="">

                                {renderPositions}

                                {/*{renderPositions1}*/}


                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </>

        return (
            <>
                <Layout>
                    {content}
                </Layout>
            </>
        );
    }
;

export default PositionsPage;
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import LayoutEmpty from "../layout/LayoutEmpty";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const InfoComponent = () => {

/*    const navigate = useNavigate();*/

    return (
        <LayoutEmpty>
{/*            <Col className="">
                <div className="text-center ">
                    <h1 className="display-1 mb-3"><b>404</b></h1>
                    <h2 className="display-5 mb-3">Oops! You seem to be lost.</h2>
                    <p>Here are some helpful links:</p>
                    <div className="d-flex flex-column flex">
                        <Link to='/'>Home</Link>
                        <Link to='/'>Back</Link>
                    </div>
                </div>
            </Col>*/}

            <Container className="align-items-center main-container">
                <div className="bg-body-tertiary p-5 rounded">
                    <h1>Navbar example</h1>
                    <p className="lead">This example is a quick exercise to illustrate how the top-aligned navbar works.
                        As you scroll, this navbar remains in its original position and moves with the rest of the
                        page.</p>
                    <a className="btn btn-lg btn-primary" href="/docs/5.3/components/navbar/" role="button">View navbar
                        docs »</a>
                </div>
            </Container>

        </LayoutEmpty>
    )

}

export default InfoComponent;

import React, {useState} from "react";
import {Outlet} from "react-router";
import {Navigate} from "react-router-dom";
import AuthService from "../../service/auth/AuthService";



/*const isAuth = useAuth();*/

const ProtectedRoutes = (props: any) => {
    const [isLoading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false)

    if (!props.redirect) {
        props.redirect = "/";
    }

    AuthService.isLoggedInPromise()
        .then(resp => {
                setLoggedIn(resp as boolean)
                setLoading(false);
        })


    if (!isLoading)
        return loggedIn ? <Outlet/> : <Navigate to={props.redirect}/>
};

export default ProtectedRoutes;

import React, {useEffect, useState} from "react";
import Card from 'react-bootstrap/Card';
import {Button, Form, Nav, ProgressBar, Spinner} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import * as yup from 'yup';
import {ErrorMessage, Formik, FormikErrors} from 'formik';
import InputGroup from "react-bootstrap/InputGroup";
import {PatternFormat} from 'react-number-format';
import {IUser, UserAccount} from "../../../../model/User";
import userService from "../../../../service/UserService";
import axiosClient from "../../../../service/axios/AxiosClient";
import UserService from "../../../../service/UserService";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import toast from "react-hot-toast";


function AccountSettings() {


    const initialValues: IUser = userService.getUser().response?.data
    const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmedPasswordShown, setConfirmedPasswordShown] = useState(false);

    async function validateUsername(value: string) {
        try {
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + "/validate-username",
                value,
                {headers: {"Content-Type": "text/plain"}}
            )
                .then((resp) => resp.data);

            console.log(response);
            return Boolean(response);
        } catch (error) {
            console.error(error);
        }
    }


    const validationSchema = yup.object().shape({
        currentPassword: yup.string().required("This field is required")
            .max(50, "Too Long!")
            .required("Input current password"),
        newPassword: yup.string().required("Password is a required field")
            .min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a symbol'),
        passwordConfirmation: yup.string().required("Password Confirmation is a required field")
            .oneOf([yup.ref("newPassword")], "Passwords must match")
    });


    return (
        <>
            <Card className="mb-4">
                <Card.Header className="border-0 pb-0" style={{background: "white"}}>
                    <Card.Title className="h5">Change your password</Card.Title>
                    <Card.Text className="mb-0"></Card.Text>
                </Card.Header>
                <Card.Body>
                    {initialValues &&

                        <Formik
                            validationSchema={validationSchema}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(true)
                                UserService.updatePassword(values.currentPassword, values.newPassword)
                                    .finally(() => setSubmitting(false))
                            }
                            }
                            enableReinitialize={false}
                            initialValues={{currentPassword: "", newPassword: "", passwordConfirmation: ""}}
                        >
                            {({
                                  handleSubmit,
                                  submitForm,
                                  isSubmitting,
                                  handleChange,
                                  handleBlur,
                                  values,
                                  initialValues,
                                  touched,
                                  isValid,
                                  dirty,
                                  errors
                              }) => (

                                <Form name="accountInfoForm" as={Row} className="g-3" onSubmit={handleSubmit}
                                      noValidate>

                                    <Form.Group as={Col} sm={12} controlId="currentPassword" className="pb-2">
                                        <Form.Label>Current password</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type={currentPasswordShown ? "text" : "password"}
                                                name="currentPassword"
                                                value={values.currentPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                /*isValid={!errors.currentPassword}
                                                isInvalid={!!errors.currentPassword}*//>

                                            <InputGroup.Text id="show-password">
                                                <Nav.Link onClick={() => {
                                                    setCurrentPasswordShown(!currentPasswordShown)
                                                }}>
                                                    {currentPasswordShown ? <FaEye/> : <FaEyeSlash/>}
                                                </Nav.Link>
                                            </InputGroup.Text>
                                            {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                                            <Form.Control.Feedback
                                                type="invalid">{errors.currentPassword as string}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} sm={12} controlId="newPassword">
                                        <Form.Label>New password</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type={newPasswordShown ? "text" : "password"}
                                                name="newPassword"
                                                value={values.newPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                isValid={!errors.newPassword}
                                                isInvalid={touched && !!errors.newPassword}/>
                                            <InputGroup.Text id="show-password">
                                                <Nav.Link onClick={() => {
                                                    setNewPasswordShown(!newPasswordShown)
                                                }}>
                                                    {newPasswordShown ? <FaEye/> : <FaEyeSlash/>}
                                                </Nav.Link>
                                            </InputGroup.Text>

                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.newPassword as string}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    {/*           <Col sm={12}>
                                        <hr className="hr"/>
                                    </Col>*/}

                                    <Form.Group as={Col} sm={12} controlId="passwordConfirmation">
                                        <Form.Label>Confirm password</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type={confirmedPasswordShown ? "text" : "password"}
                                                name="passwordConfirmation"
                                                value={values.passwordConfirmation ?? ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur} // This apparently updates `touched`
                                                isValid={!errors.passwordConfirmation}
                                                isInvalid={!!errors.passwordConfirmation}/>
                                            <InputGroup.Text id="show-password">
                                                <Nav.Link onClick={() => {
                                                    setConfirmedPasswordShown(!confirmedPasswordShown)
                                                }}>
                                                    {confirmedPasswordShown ? <FaEye/> : <FaEyeSlash/>}
                                                </Nav.Link>
                                            </InputGroup.Text>
                                            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                                            <Form.Control.Feedback
                                                type="invalid">{errors.passwordConfirmation as string}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>


                                    <Col className="col-12 text-end mt-4">
                                        <Button type="submit" variant="primary" onClick={submitForm}
                                                disabled={!(dirty && isValid && !isSubmitting)}>
                                            {isSubmitting ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="me-2"
                                                    />
                                                    Please wait...
                                                </> : "Save changes"}
                                        </Button>
                                    </Col>
                                </Form>
                            )}
                        </Formik>
                    }
                </Card.Body>
            </Card>


            {/*        <Card>
            <Card.Header className="border-0 pb-0" style={{background: "white"}}>
                <Card.Title>Change your password</Card.Title>
                <Card.Text className="mb-0">See resolved goodness felicity shy civility domestic had
                    but.</Card.Text>
            </Card.Header>
            <Card.Body>
                <Form className="row g-3">

                    <div className="col-12">
                        <label className="form-label">Current password</label>
                        <input type="text" className="form-control" placeholder=""/>
                    </div>

                    <div className="col-12">
                        <label className="form-label">New password</label>

                        <div className="input-group">
                            <input className="form-control fakepassword" type="password" id="psw-input"
                                   placeholder="Enter new password"/>
                            <span className="input-group-text p-0">
                          <i className="fakepasswordicon fa-solid fa-eye-slash cursor-pointer p-2 w-40px"></i>
                        </span>
                        </div>

                        <div id="pswmeter" className="mt-2 password-strength-meter">
                            <div className="password-strength-meter-score"></div>
                        </div>
                        <div id="pswmeter-message" className="rounded mt-1">Write your password...</div>
                    </div>

                    <div className="col-12">
                        <label className="form-label">Confirm password</label>
                        <input type="text" className="form-control" placeholder=""/>
                    </div>

                    <div className="col-12 text-end">
                        <button type="submit" className="btn btn-primary mb-0">Update password</button>
                    </div>
                </Form>
            </Card.Body>
        </Card>*/
            }
        </>
    )
        ;
}

export default AccountSettings;

import useAxios from "../hooks/useAxios";
import axiosClient from "./axios/AxiosClient";
import authHeader from "./auth/auth-header";
import {IStockFinancials} from "../model/StockFinancials";
import {useState} from "react";
import {IStock, IStockHistory} from "../model/Stock";
import {ITimeSeries} from "../model/TimeSeries";


const STOCK_DATA_URL = process.env.REACT_APP_STOCK_DATA_URL;


class StockService {


      getRequest(URL:string) {
        return axiosClient.get(`/${URL}`).then(response => response);
    }


/*    getBySymbol(symbol: string) {
        return useAxios({
            method: "GET",
            url: STOCK_DATA_URL + "/stock/symbol/" + symbol
        });
    }*/

/*
    getBySymbol = async (symbol: string) => {
        const response = await axiosClient.get<IStock>(STOCK_DATA_URL + "/stock/symbol/" + symbol);
        return response;
    };
*/

    getBySymbol(symbol: string)  {
        const response =  axiosClient.get<IStock>(STOCK_DATA_URL + "/stock/symbol/" + symbol);
        return response;
    }


    getById(id: number) {
        return useAxios({
            method: "GET",
            url: STOCK_DATA_URL + "/stock/id/" + id
        });
    }

    getAll()  {
        const response =  axiosClient.get<IStock[]>(STOCK_DATA_URL + "/stocks/all");
        return response;
    }

    getAllActive()  {
        const response =  axiosClient.get<IStock[]>(STOCK_DATA_URL + "/stocks/active");
        return response;
    }


    getEOD(symbol: string) {
        return useAxios({
            method: "GET",
            url: STOCK_DATA_URL + "/timeseries/eod/last/" + symbol
        });
    }

/*    getStockFinancials(symbol: string) {
        return useAxios({
            method: "GET",
            url: STOCK_DATA_URL + "/financials/last/" + symbol
        });
    }*/


    async getStockFinancials(symbol: string) {
        return axiosClient.get<IStockFinancials>(STOCK_DATA_URL + "/financials/" + symbol)
    }


    /*    getStockFinancialsPromise(symbol: string) {
            return axiosClient.get<IStockFinancials>(STOCK_DATA_URL + "/financials/last/" + symbol)
        }*/


    /*    getBySymbol(symbol: string) {
            return axios.get<IStock>(STOCK_DATA_URL + "/stock/symbol/" + symbol, {headers: authHeader()});
        }*/

    getPrice(symbol: string) {
        return axiosClient.get<number>(STOCK_DATA_URL + "/stock/" + symbol + "/price", {headers: authHeader()});
    }

    /*    getEOD<ITimeSeriesEod>(symbol: string) {
            return axios.get(STOCK_DATA_URL + "/timeseries/eod/last/" + symbol, {headers: authHeader()});
        }*/

    getTimeSeries(symbol: string, period: string) {
        return axiosClient.get<ITimeSeries[]>(STOCK_DATA_URL + "/timeseries/" + symbol + "?period=" + period, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/stream+json"
            }
        })
    }

    getAllTimeSeries(symbol: string) {
        return axiosClient.get(STOCK_DATA_URL + "/timeseries/" + symbol + "/all", {
            /*            headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/stream+json"
                        }*/
        })
    }

    getMarketStatus() {
        return useAxios({
            method: "GET",
            url: STOCK_DATA_URL + "/stock/market/status"
        });
    }


    getMarketStatusPromise() {
        return axiosClient.get<string>(STOCK_DATA_URL + "/stock/market/status")
    }


/*
    getMarketStatusPromise = async () => {
        const response = await axiosClient.get<string>(STOCK_DATA_URL + "/stock/market/status");
    };
*/


    getTopGainers(stockId:number) {
        return axiosClient.get<IStockFinancials[]>(STOCK_DATA_URL + "/stock/topgainers?stockId=" + stockId)
    }
}

export default new StockService();
import axios from 'axios';
import {ISearchResponse} from "../model/Search";


const BASE_URL = process.env.REACT_APP_BASE_URL;
const STOCKS_URL = process.env.REACT_APP_STOCK_DATA_URL;

class SearchService {

/*    search(search: string) {
        console.log("search = " + search)
        //return axiosClient.get<IStockDto[]>(STOCKS_URL + "/stock/search/" + search);
        return axios.get(STOCKS_URL + "/stock/search/" + search, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/stream+json"
            }
        });
    }*/


    search(search: string, includePrice = false) {
        return axios.post<ISearchResponse>(BASE_URL + "/search",
            {text: search,
            includePrice: includePrice}
    );
    }


     getSearchResults = async (search: string) => {
        const response = await axios.get(STOCKS_URL + "/stock/search/" + search)
        console.log(response)
        return response.data;
    }




}

export default new SearchService();

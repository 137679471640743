import CardHeader from "react-bootstrap/CardHeader";
import {Badge, CardBody} from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import React, {useEffect, useState} from "react";
import StockService from "../../service/StockService";
import {IStock} from "../../model/Stock";
import {IStockFinancials} from "../../model/StockFinancials";
import {Link, useNavigate} from "react-router-dom";
import * as Util from "node:util";
import Utils from "../../service/utils/Utils";

const StockTopGainersComponent = (props: any) => {

    const stock = props.stock
    const [stocks, setStocks] = useState<IStockFinancials[]>([])

    useEffect(() => {
        if (stock !== undefined)
            StockService.getTopGainers(stock.id)
                .then(response => {
                    setStocks(response.data)
                })
    }, [stock]);


    const content =

        stocks?.map((s, index) => {
                const x = ((s.price - s.price1d) / s.price1d) * 100
                const y = Utils.formatPrice(x, 2, false, true)
                const diff = x >= 0 ?
                    <Badge bg="success" className="p-2" style={{minWidth: 65}}>{y}%</Badge> :
                    <Badge bg="danger" className="p-2" style={{minWidth: 65}}>{y}%</Badge>

                return (
                    <a href={'/stock/' + s.symbol} className="text-decoration-none" key={index}>
                        <ListGroup.Item action className="p-2">

                            <div className="d-flex justify-content-between align-middle">
                                <div className="pe-1">
                                    {s.name}
                                    <div className="fw-semibold">{Utils.formatPrice(s.price)} USD</div>
                                </div>

                                <h5 className="my-auto">{diff}</h5>

                            </div>
                        </ListGroup.Item>
                    </a>
                )

            }
        )


    return <>
        {content?.length > 0 &&
            <Card>
                <CardHeader>
                    Top Gainers in the Industry
                </CardHeader>
                <CardBody className="p-0">
                    <ListGroup variant="flush">
                        {content}
                    </ListGroup>
                </CardBody>
            </Card>
        }
    </>

}

export default StockTopGainersComponent
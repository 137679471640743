import React, {useEffect, useState} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import './search-header.css'
import {Form} from "react-bootstrap";
import SearchService from "../../service/SearchService";
import useDebounce from "../../hooks/useDebounce";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {Formik, useFormikContext} from "formik";
import {ISearchResponse} from "../../model/Search";


export default function SearchHeaderComponent() {

    const [searchResults, setSearchResults] = useState<ISearchResponse>({} as ISearchResponse);
    const [searchText, setSearchText] = useState('')
    const debouncedSearchText = useDebounce(searchText, 500);
    const [isOpen, setIsOpen] = useState(false);

    const ref = useOutsideClick(() => {
        console.log('Clicked outside of header search');
        setIsOpen(false);
    });



    useEffect(() => {
         console.log("searchText = " + searchText)
          console.log("debounced searchText = " + debouncedSearchText)
        if (debouncedSearchText.trim().length > 0) {
            console.log("debounced searchText length = " + debouncedSearchText.length)
            SearchService.search(debouncedSearchText)
                .then(response => setSearchResults(response.data))
        }
    }, [debouncedSearchText])


    function handleSearchTextChange(e: any) {
        e.preventDefault();
        setSearchText(e.target.value);
    }


    function focusHandler() {
        setIsOpen(true);
    }

    return (
        <>
            <Formik
                onSubmit={(values) => {
                    window.location.href = '/search?text=' + values.searchText
                }
                }
                enableReinitialize={true}
                initialValues={{searchText: ''}}
                handleChange
            >
                {({
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      initialValues,
                      touched,
                      errors,


                  }) => (
                    <Form className="search-form" onSubmit={handleSubmit}>
                        <Form.Control
                            type="search"
                            name="searchText"
                            placeholder="Search"
                            className={(searchText && isOpen && (searchResults?.stocks?.length > 0 || searchResults?.users?.length > 0)) ? "bg-light borders-radius-0-bottom search-form-control" : "bg-light search-form-control"}
                            aria-label="Search"
                            value={values.searchText}
                            onBlur={handleBlur} // This apparently updates `touched`
                            onChange={e => {
                                handleChange(e)
                            handleSearchTextChange(e)}}
                            onFocus={focusHandler}
                        />
                        {searchText && isOpen &&
                            <>
                                <ListGroup className="search-results borders-radius-0-top">
                                    {searchResults?.stocks?.map((stock, i) => (<>
                                                <div key={i}>
                                                    <ListGroup.Item key={i} action href={'/stock/' + stock.symbol}
                                                                    className="search-results-item">
                                                        <div className="d-flex justify-content-between">
                                                            <div><b>{stock.symbol}</b> &nbsp; {stock.name}</div>
                                                            <div>{stock.exchange}</div>
                                                        </div>
                                                    </ListGroup.Item>
                                                </div>
                                            </>
                                        )
                                    )
                                    }
                                </ListGroup>

                                <ListGroup className="search-results borders-radius-0-top">
                                    {searchResults?.users?.map((user, i) => (<>
                                                <div key={i}>
                                                    <ListGroup.Item key={i} action href={'/user/' + user.id}
                                                                    className="search-results-item">
                                                        <div className="d-flex justify-content-between">
                                                            <div>{user.thumbnail}</div>
                                                            <div><b>{user.firstName}</b> &nbsp; {user.lastName}</div>
                                                            <div>@{user.username}</div>
                                                        </div>
                                                    </ListGroup.Item>
                                                </div>
                                            </>
                                        )
                                    )
                                    }
                                </ListGroup>
                            </>
                        }
                    </Form>
                )}
            </Formik>
        </>

    );
}